import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";
// import images
// import logodarkImg from "../../assets/images/logo-dark.png";
// import logosmImg from "../../assets/images/logo-sm.png";
// import logolightImg from "../../assets/images/logo-light.png";

// import images
import logodarkImg from "../../assets/images/mm_logo.jpg";
import logosmImg from "../../assets/images/mm_small_new.jpg";
import logolightImg from "../../assets/images/mm_logo.jpg";
import lang_icon from "../../assets/images/lang_mm.png";

// Import other Dropdown
import LanguageDropdown from "../../components/LanguageDropdown";
//import NotificationDropdown from "../../components/NotificationDropdown";
import ProfileMenu from "../../components/ProfileMenu";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

const TopBarSelect = {
  marginTop: "18px",
  width: "auto",
};

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchOpen: false,
    };
    this.state = {
      value: localStorage.getItem("lang"),
      daysvalue: localStorage.getItem("data_visible_days"),
      sel_product: localStorage.getItem("sel_product"),
    };

    i18next.changeLanguage(localStorage.getItem("lang"));
    this.handleChange = this.handleChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }
  handleChange(lang) {
    localStorage.setItem("data_visible_days", lang.target.value);
    //alert(lang.target.value);
    window.location.reload(false);
  }
  handleProductChange(prod) {
    localStorage.setItem("sel_product", prod.target.value);
    //alert(lang.target.value);
    window.location.reload(false);
  }
  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/home" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosmImg} alt="" height="40" />
                  </span>
                  <span className="logo-lg">
                    <img src={logodarkImg} alt="" height="35" />
                  </span>
                </Link>

                <Link to="/home" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosmImg} alt="" height="30" />
                  </span>
                  <span className="logo-lg">
                    <img src={logolightImg} alt="" height="60" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="mdi mdi-menu"></i>
              </button>

              <div className="d-none d-sm-block">
                <Row className="form-group">
                  <Col sm={12}>
                    <select
                      className="form-control"
                      style={TopBarSelect}
                      value={this.state.daysvalue}
                      onChange={this.handleChange}
                    >
                      <option value="0">{t("topbar.overall")}</option>
                      <option value="14">
                        {t("topbar.last_14_days_daily")}
                      </option>
                      <option value="30">
                        {t("topbar.last_30_days_daily")}
                      </option>
                      <option value="90">{t("topbar.last_year")}</option>
                      <option value="180">{t("topbar.this_year")}</option>
                      <option value="365">{t("topbar.total")}</option>
                    </select>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="d-flex">
              <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen"></i>
                </button>
              </div>
              <img
                className="lang_icon"
                src={lang_icon}
                alt="Header Language"
                height="20"
              />
              <LanguageDropdown />
              <select
                className="form-control"
                style={TopBarSelect}
                value={this.state.sel_product}
                onChange={this.handleProductChange}
              >
                <option value="all">{t("topbar.prod_sel")}</option>
                <option value="MM">Magneti Marelli</option>
                {/* <option value="TM">Tecnomotor iONE Cruiser</option> */}
              </select>

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="mdi mdi-settings-outline"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default withTranslation()(TopBar);

import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import logoSm from "../../assets/images/mm_logo.jpg";
import i18next from "i18next";
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import { withTranslation } from "react-i18next";
import config from "./../../helpers/Constants";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";

class DashboardReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: localStorage.getItem("lang"),
      daysvalue: localStorage.getItem("data_visible_days"),
      loading: true,
      isActive: true,
      t_div_by_type_total_counts_bugs_count: 0,
      t_div_by_type_total_counts_new_feat_count: 0,
      t_div_by_type_total_counts_improvement_count: 0,
      t_div_by_type_total_counts_task_count: 0,
      t_div_by_type_total_counts_new_func_count: 0,
      t_div_by_type_open_counts_bugs_count: 0,
      t_div_by_type_open_counts_new_feat_count: 0,
      t_div_by_type_open_counts_improvement_count: 0,
      t_div_by_type_open_counts_task_count: 0,
      t_div_by_type_open_counts_new_func_count: 0,
      t_div_by_type_status_RICEVUTA: 0,
      t_div_by_type_status_IN_ANALISI: 0,
      t_div_by_type_status_t_IN_LAVORAZIONE: 0,
      t_div_by_type_status_CANCELLATA: 0,
      t_div_by_type_status_IN_TEST: 0,
      t_div_by_type_status_IN_ATTESA_DI_RILASCIO: 0,
      t_div_by_type_status_RILASCIATA: 0,
      t_div_by_type_status_RIFIUTATA: 0,
      t_div_by_type_status_IN_ATTESA: 0,
      t_div_by_type_status_NUOVO_SVILUPPO: 0,
      t_div_by_type_label_bug_SINOTTICO: 0,
      t_div_by_type_label_bug_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_bug_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_bug_DATABASE: 0,
      t_div_by_type_label_bug_STAR: 0,
      t_div_by_type_label_bug_ERRORI: 0,
      t_div_by_type_label_bug_PARAMETRI_STATI: 0,
      t_div_by_type_label_bug_VIN: 0,
      t_div_by_type_label_bug_EOBD: 0,
      t_div_by_type_label_bug_open_SINOTTICO: 0,
      t_div_by_type_label_bug_open_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_bug_open_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_bug_open_DATABASE: 0,
      t_div_by_type_label_bug_open_STAR: 0,
      t_div_by_type_label_bug_open_ERRORI: 0,
      t_div_by_type_label_bug_open_PARAMETRI_STATI: 0,
      t_div_by_type_label_bug_open_VIN: 0,
      t_div_by_type_label_bug_open_EOBD: 0,
      t_div_by_type_label_new_feat_SINOTTICO: 0,
      t_div_by_type_label_new_feat_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_new_feat_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_new_feat_DATABASE: 0,
      t_div_by_type_label_new_feat_STAR: 0,
      t_div_by_type_label_new_feat_ERRORI: 0,
      t_div_by_type_label_new_feat_PARAMETRI_STATI: 0,
      t_div_by_type_label_new_feat_VIN: 0,
      t_div_by_type_label_new_feat_EOBD: 0,
      t_div_by_type_label_new_feat_open_SINOTTICO: 0,
      t_div_by_type_label_new_feat_open_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_new_feat_open_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_new_feat_open_DATABASE: 0,
      t_div_by_type_label_new_feat_open_STAR: 0,
      t_div_by_type_label_new_feat_open_ERRORI: 0,
      t_div_by_type_label_new_feat_open_PARAMETRI_STATI: 0,
      t_div_by_type_label_new_feat_open_VIN: 0,
      t_div_by_type_label_new_feat_open_EOBD: 0,
      t_div_by_type_label_imp_SINOTTICO: 0,
      t_div_by_type_label_imp_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_imp_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_imp_DATABASE: 0,
      t_div_by_type_label_imp_STAR: 0,
      t_div_by_type_label_imp_ERRORI: 0,
      t_div_by_type_label_imp_PARAMETRI_STATI: 0,
      t_div_by_type_label_imp_VIN: 0,
      t_div_by_type_label_imp_EOBD: 0,
      t_div_by_type_label_imp_open_SINOTTICO: 0,
      t_div_by_type_label_imp_open_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_imp_open_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_imp_open_DATABASE: 0,
      t_div_by_type_label_imp_open_STAR: 0,
      t_div_by_type_label_imp_open_ERRORI: 0,
      t_div_by_type_label_imp_open_PARAMETRI_STATI: 0,
      t_div_by_type_label_imp_open_VIN: 0,
      t_div_by_type_label_imp_open_EOBD: 0,
      d_list: "",
      bug_items: "",
      imp_items: "",
      nf_items: "",
      task_items: "",
      new_func_items: "",
      open_tickets: 0,
      closed_tickets: 0,
      curr_date: "",
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var data_visible_days = localStorage.getItem("data_visible_days");

    this.setState({
      sum_au_daysvalue: data_visible_days,
      sum_ap_daysvalue: data_visible_days,
      sum_all_ap_daysvalue: data_visible_days,
      sum_os_daysvalue: data_visible_days,
      curr_date: moment().format("DD-MM-YYYY hh:mm:ss"),
    });

    if (!data_visible_days) {
      var day = "14";
    } else {
      day = data_visible_days;
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        //console.log(findresponses);
        this.setState({
          loading: false,
        });
        if (findresponses.users !== 0 || findresponses.diagnosis !== 0) {
        } else {
          window.history.back();
        }
      });

    fetch(
      config.API + "get_tickets_dash_tick_div_by_type_total?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_total_counts_bugs_count: findresponse.bugs_count,
          t_div_by_type_total_counts_improvement_count:
            findresponse.improvement_count,
          t_div_by_type_total_counts_new_feat_count:
            findresponse.new_feat_count,
          t_div_by_type_total_counts_new_func_count: findresponse.new_func,
        });
      });

    fetch(
      config.API + "get_tickets_dash_tick_div_by_type_open?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          t_div_by_type_open_counts_bugs_count: findresponse.bugs_count,
          t_div_by_type_open_counts_improvement_count:
            findresponse.improvement_count,
          t_div_by_type_open_counts_new_feat_count: findresponse.new_feat_count,
          t_div_by_type_open_counts_new_func_count: findresponse.new_func,
        });
      });

    fetch(config.API + "get_tickets_dash_tick_div_by_status?company=Nexion", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_status_RICEVUTA: findresponse.RICEVUTA,
          t_div_by_type_status_IN_ANALISI: findresponse.IN_ANALISI,
          t_div_by_type_status_t_IN_LAVORAZIONE: findresponse.IN_LAVORAZIONE,
          t_div_by_type_status_CANCELLATA: findresponse.CANCELLATA,
          t_div_by_type_status_IN_TEST: findresponse.IN_TEST,
          t_div_by_type_status_IN_ATTESA_DI_RILASCIO:
            findresponse.IN_ATTESA_DI_RILASCIO,
          t_div_by_type_status_RILASCIATA: findresponse.RILASCIATA,
          t_div_by_type_status_RIFIUTATA: findresponse.RIFIUTATA,
          t_div_by_type_status_IN_ATTESA: findresponse.IN_ATTESA,
          t_div_by_type_status_NUOVO_SVILUPPO: findresponse.NUOVO_SVILUPPO,
        });
      });

    //Bugs
    fetch(
      config.API + "get_tickets_dash_tick_div_by_labels_bugs?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_bug_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_bug_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_bug_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_bug_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_bug_STAR: findresponse.STAR,
          t_div_by_type_label_bug_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_bug_PARAMETRI_STATI: findresponse.PARAMETRI_STATI,
          t_div_by_type_label_bug_VIN: findresponse.VIN,
          t_div_by_type_label_bug_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_bugs_open?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_bug_open_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_bug_open_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_bug_open_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_bug_open_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_bug_open_STAR: findresponse.STAR,
          t_div_by_type_label_bug_open_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_bug_open_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_bug_open_VIN: findresponse.VIN,
          t_div_by_type_label_bug_open_EOBD: findresponse.EOBD,
        });
      });

    //New Feat
    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_new_feat?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_new_feat_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_new_feat_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_new_feat_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_new_feat_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_new_feat_STAR: findresponse.STAR,
          t_div_by_type_label_new_feat_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_new_feat_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_new_feat_VIN: findresponse.VIN,
          t_div_by_type_label_new_feat_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_new_feat_open?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_new_feat_open_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_new_feat_open_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_new_feat_open_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_new_feat_open_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_new_feat_open_STAR: findresponse.STAR,
          t_div_by_type_label_new_feat_open_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_new_feat_open_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_new_feat_open_VIN: findresponse.VIN,
          t_div_by_type_label_new_feat_open_EOBD: findresponse.EOBD,
        });
      });

    // Improvement
    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_improvement?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_imp_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_imp_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_imp_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_imp_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_imp_STAR: findresponse.STAR,
          t_div_by_type_label_imp_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_imp_PARAMETRI_STATI: findresponse.PARAMETRI_STATI,
          t_div_by_type_label_imp_VIN: findresponse.VIN,
          t_div_by_type_label_imp_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_improvement_open?company=Nexion",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_imp_open_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_imp_open_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_imp_open_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_imp_open_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_imp_open_STAR: findresponse.STAR,
          t_div_by_type_label_imp_open_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_imp_open_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_imp_open_VIN: findresponse.VIN,
          t_div_by_type_label_imp_open_EOBD: findresponse.EOBD,
        });
      });

    fetch(config.API + "get_tickets_dash_tick_div_by_days?company=Nexion", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          d_list: findresponse.diagnosis_items.d_list,
          bug_items: findresponse.diagnosis_items.bug_items,
          imp_items: findresponse.diagnosis_items.imp_items,
          nf_items: findresponse.diagnosis_items.nf_items,
          new_func_items: findresponse.diagnosis_items.new_func_items,
          isActive: false,
        });
      });

    fetch(config.API + "get_tickets_dash_tick_open_closed?company=Nexion", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          open_tickets: findresponse.Open_Tickets,
          closed_tickets: findresponse.Closed_Tickets,
        });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            JIRA Tickets Dashboard Report | Magneti Marelli Web Portal
          </title>
          <meta
            name="description"
            content="Diagnostic Portal For Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#ffd200"
          color={"#002C77"}
        />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box"></div>
            </Col>
          </Row>

          <Row>
            <div className="col-1"></div>
            <div className="col-10 report_class">
              <Card>
                <CardBody className="report_card">
                  <Row>
                    <div className="col-12">
                      <Row>
                        <div className="col-8">
                          <h2 className="font-size-20">
                            <i class="mdi mdi-jira jira_report"></i>JIRA
                            DASHBOARD REPORT
                          </h2>
                          <p>
                            <b>Report Date:</b> {this.state.curr_date}
                          </p>
                        </div>
                        <div className="col-4">
                          <div className="invoice-title">
                            <img
                              src={logoSm}
                              className="float-right"
                              alt="logo"
                              height="50"
                            />
                          </div>
                        </div>
                      </Row>
                      <hr />
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>
                              {t("jira_tickets_page.divided_by_type_total")}
                            </h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 rep-table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>BUG</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>MIGLIORAMENTO</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>NUOVO SVILUPPO</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>NUOVO FUNZIONALITA</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    {
                                      this.state
                                        .t_div_by_type_total_counts_bugs_count
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      this.state
                                        .t_div_by_type_total_counts_improvement_count
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      this.state
                                        .t_div_by_type_total_counts_new_feat_count
                                    }
                                  </td>
                                  <td className="text-right">
                                    {
                                      this.state
                                        .t_div_by_type_total_counts_new_func_count
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>
                              {t("jira_tickets_page.divided_by_type_open")}
                            </h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 rep-table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>BUG</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>MIGLIORAMENTO</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>NUOVO SVILUPPO</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>NUOVO FUNZIONALITA</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    {
                                      this.state
                                        .t_div_by_type_open_counts_bugs_count
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      this.state
                                        .t_div_by_type_open_counts_improvement_count
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      this.state
                                        .t_div_by_type_open_counts_new_feat_count
                                    }
                                  </td>
                                  <td className="text-right">
                                    {
                                      this.state
                                        .t_div_by_type_open_counts_new_func_count
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.divided_by_status")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>RICEVUTA</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_RICEVUTA}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>IN ANALISI</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_IN_ANALISI}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>IN LAVORAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_status_t_IN_LAVORAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>CANCELLATA</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_CANCELLATA}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>IN TEST</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_IN_TEST}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>IN ATTESA DI RILASCIO</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_status_IN_ATTESA_DI_RILASCIO
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>RILASCIATA</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_RILASCIATA}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>RIFIUTATA</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_RIFIUTATA}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>IN ATTESA</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_status_IN_ATTESA}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVO SVILUPPO</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_status_NUOVO_SVILUPPO
                                  }
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>
                              {t("jira_tickets_page.open_closed_tickets")}
                            </h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 rep-table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>OPEN</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>CLOSED</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    {this.state.open_tickets}
                                  </td>
                                  <td className="text-center">
                                    {this.state.closed_tickets}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.label_bugs_total")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>SINOTTICO</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_SINOTTICO}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVA_IMPLEMENTAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_NUOVA_IMPLEMENTAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ATTIVAZIONI_CODIFICHE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_ATTIVAZIONI_CODIFICHE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>DATABASE</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_DATABASE}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>STAR</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_STAR}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ERRORI</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_ERRORI}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>PARAMETRI_STATI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_PARAMETRI_STATI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>VIN</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_VIN}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>EOBD</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_EOBD}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.label_bugs_open")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>SINOTTICO</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_open_SINOTTICO
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVA_IMPLEMENTAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_open_NUOVA_IMPLEMENTAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ATTIVAZIONI_CODIFICHE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_open_ATTIVAZIONI_CODIFICHE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>DATABASE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_open_DATABASE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>STAR</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_open_STAR}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ERRORI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_open_ERRORI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>PARAMETRI_STATI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_bug_open_PARAMETRI_STATI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>VIN</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_open_VIN}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>EOBD</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_bug_open_EOBD}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.label_imp_total")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>SINOTTICO</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_SINOTTICO}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVA_IMPLEMENTAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_NUOVA_IMPLEMENTAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ATTIVAZIONI_CODIFICHE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_ATTIVAZIONI_CODIFICHE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>DATABASE</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_DATABASE}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>STAR</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_STAR}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ERRORI</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_ERRORI}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>PARAMETRI_STATI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_PARAMETRI_STATI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>VIN</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_VIN}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>EOBD</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_EOBD}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.label_imp_open")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>SINOTTICO</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_open_SINOTTICO
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVA_IMPLEMENTAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_open_NUOVA_IMPLEMENTAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ATTIVAZIONI_CODIFICHE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_open_ATTIVAZIONI_CODIFICHE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>DATABASE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_open_DATABASE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>STAR</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_open_STAR}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ERRORI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_open_ERRORI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>PARAMETRI_STATI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_imp_open_PARAMETRI_STATI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>VIN</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_open_VIN}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>EOBD</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_imp_open_EOBD}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.label_nf_total")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>SINOTTICO</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_SINOTTICO
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVA_IMPLEMENTAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_NUOVA_IMPLEMENTAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ATTIVAZIONI_CODIFICHE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_ATTIVAZIONI_CODIFICHE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>DATABASE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_DATABASE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>STAR</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_new_feat_STAR}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ERRORI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_ERRORI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>PARAMETRI_STATI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_PARAMETRI_STATI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>VIN</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_new_feat_VIN}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>EOBD</strong>
                                </th>
                                <td className="text-left">
                                  {this.state.t_div_by_type_label_new_feat_EOBD}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.label_nf_open")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive rep-table-bordered">
                            <table className="table table-sm m-0">
                              <tr>
                                <th className="text-left">
                                  <strong>SINOTTICO</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_SINOTTICO
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>NUOVA_IMPLEMENTAZIONE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_NUOVA_IMPLEMENTAZIONE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ATTIVAZIONI_CODIFICHE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_ATTIVAZIONI_CODIFICHE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>DATABASE</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_DATABASE
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>STAR</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_STAR
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>ERRORI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_ERRORI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>PARAMETRI_STATI</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_PARAMETRI_STATI
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>VIN</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_VIN
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  <strong>EOBD</strong>
                                </th>
                                <td className="text-left">
                                  {
                                    this.state
                                      .t_div_by_type_label_new_feat_open_EOBD
                                  }
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Final Table with print Button */}
                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>{t("jira_tickets_page.divide_by_type_day")}</h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <LoadingOverlay
                              active={this.state.isActive}
                              spinner
                              text={t("jira_tickets_page.loading_text")}
                            >
                              <table className="table table-sm m-0 rep-table-bordered">
                                <thead>
                                  <tr>
                                    <td className="text-center">
                                      <strong>Date</strong>
                                    </td>
                                    <td className="text-center">
                                      <strong>BUG</strong>
                                    </td>
                                    <td className="text-center">
                                      <strong>MIGLIORAMENTO</strong>
                                    </td>
                                    <td className="text-center">
                                      <strong>NUOVO SVILUPPO</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>NUOVO FUNZIONALITA</strong>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[0]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[0]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[0]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[0]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[0]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[1]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[1]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[1]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[1]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[1]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[2]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[2]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[2]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[2]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[2]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[3]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[3]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[3]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[3]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[3]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[4]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[4]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[4]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[4]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[4]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[5]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[5]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[5]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[5]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[5]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[6]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[6]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[6]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[6]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[6]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[7]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[7]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[7]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[7]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[7]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[8]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[8]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[8]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[8]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[8]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[9]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[9]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[9]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[9]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[9]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[10]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[10]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[10]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[10]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[10]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[11]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[11]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[11]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[11]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[11]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[12]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[12]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[12]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[12]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[12]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[13]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[13]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[13]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[13]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[13]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      {this.state.d_list[14]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.bug_items[14]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.imp_items[14]}
                                    </td>
                                    <td className="text-center">
                                      {this.state.nf_items[14]}
                                    </td>
                                    <td className="text-right">
                                      {this.state.new_func_items[14]}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </LoadingOverlay>
                          </div>
                          <br />
                          <br />
                          <div className="d-print-none">
                            <div className="float-right">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.print();
                                }}
                                className="btn btn-mm waves-effect waves-light"
                              >
                                <i className="fa fa-print"> Stampa Report</i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="col-1"></div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DashboardReport);

import React, { Component } from "react";
import { Row, Col, Card, CardBody} from "reactstrap"; 
import { Link } from "react-router-dom";
import queryString from 'query-string';
import moment from 'moment';
import {MetaTags} from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import {Sugar} from 'react-preloaders';
import config from './../../helpers/Constants';

class AcOperationsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operations:[],
      operations_details:[],
      version:[],
      location:[],
      vehicle:[],
      enabled_features:[],
      customer:[],
      modal_standard: false,
      d_date:'',
      continent_code:'',
      continent_name:'',
      country_code:'',
      country_name:'',
      ip_address:'',
      state_prov:'',
      city:'',
      cus_name:'',
      cus_phone:'',
      cus_address:'',
      loading: true
    };
  
    this.tog_standard = this.tog_standard.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    i18next.changeLanguage(localStorage.getItem('lang'));

  }

  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  tog_standard(loc) {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;

  fetch(config.API+'get_user_permissions/'+user_id, {
    headers: {  
      'Nexion_API_Key': config.API_KEY
    }
  }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
    if(findresponses.ac_operations === 0){
      window.history.back();
    }
  })
  const values = queryString.parse(this.props.location.search);
  const ops_id = values.operation_id;
  fetch(config.API+'ac_operations/'+ops_id, {
    headers: {  
      'Nexion_API_Key': config.API_KEY
    }
  }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
    this.setState({
      operations_details:findresponse.operations_details,
      vehicle:findresponse.selected_vehicle,
      version:findresponse.versions,
      operations:findresponse.operations,
      enabled_features:findresponse.enabled_features,
      d_date:findresponse.operations_details.diagnosis_date.replace(/T/, ' ').replace(/\..+/, '')
    })
    if(findresponse.location !== undefined){
    this.setState({
      continent_code:findresponse.location.continent_code,
      continent_name:findresponse.location.continent_name,
      country_code:findresponse.location.country_code,
      country_name:findresponse.location.country_name,
      city:findresponse.location.city,
      state_prov:findresponse.location.state_prov,
      ip_address:findresponse.location.ip_address,
    })
    }
    if(findresponse.customer !== undefined){
        this.setState({
          cus_name:findresponse.customer.name,
          cus_phone:findresponse.customer.telephone,
          cus_address:findresponse.customer.address,
          loading:false
        })
        }
  })
}

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
      <title>AC Operation Details | Tecnomotor Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ffd200" color={'#002C77'} />
        <div className="container-fluid homepageFirstRow">
          <Row className="diag_info_div">
          <Col lg={3}>
            <Card>
              <CardBody>
                <p><b>{t('diagnosisdetailspage.diag_id')}:</b> {this.state.operations_details.id}</p>
              </CardBody>
            </Card>
            </Col>
            <Col lg={3}>
            <Card>
              <CardBody>
              <p><b>{t('diagnosisdetailspage.serial')}:</b> {this.state.operations_details.serial_number}</p>
              </CardBody>
            </Card>
            </Col>
            <Col lg={3}>
            <Card>
              <CardBody>
              <p><b>{t('diagnosisdetailspage.device_name')}:</b> {this.state.operations_details.device_name}</p>
              </CardBody>
            </Card>
            </Col>
            <Col lg={3}>
            <Card>
              <CardBody>
              <p><b>{t('diagnosisdetailspage.lang')}:</b> {this.state.operations_details.language}</p>
              </CardBody>
            </Card>
            </Col>
          </Row>
          <Row className="diag_info_div">
          <Col lg={3}>
            <Card>
              <CardBody>
                <p><b>{t('diagnosisdetailspage.company')}:</b> {this.state.operations_details.company}</p>
              </CardBody>
            </Card>
            </Col>
            <Col lg={3}>
            <Card>
              <CardBody>
              <p><b>OS Version:</b> {this.state.operations_details.os_version}</p>
              </CardBody>
            </Card>
            </Col>
            <Col lg={3}>
            <Card>
              <CardBody>
              <p><b>{t('diagnosisdetailspage.pass_exp')}:</b><span className="pass_text"> {this.state.operations_details.password }</span></p>
              </CardBody>
            </Card>
            </Col>
            <Col lg={3}>
            <Card>
              <CardBody>
                
              <p><b>{t('diagnosisdetailspage.diagnosis_date')}:</b> {this.state.d_date}</p>
              </CardBody>
            </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
            <Card>
              <CardBody>
              <h4 className="card-title">{t('diagnosisdetailspage.version_details')}</h4>

                  <div className="table-responsive">
                    <table className="table table-sm m-0">
                      <thead>
                        <tr>
                        
                          <th>{t('diagnosisdetailspage.version')}</th>
                          <th>{t('diagnosisdetailspage.value')}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>BOOTLOADER</td>
                        <td>{this.state.version.bootloader}</td>
                        </tr>

                        <tr>
                        <td>HMI</td>
                        <td>{this.state.version.hmi}</td>
                        </tr>
                        <tr>
                          <td>PLC</td>
                          <td>{this.state.version.plc}</td>
                        </tr>
                        <tr>
                        <td>{t('diagnosisdetailspage.veh_db')}</td>
                        <td>{this.state.version.vehicle_database}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
              <h4 className="card-title">{t('diagnosisdetailspage.enabled_feat')}</h4>

                  <div className="table-responsive">
                    <table className="table table-sm m-0">
                      <thead>
                        <tr>
                        
                          <th>{t('diagnosisdetailspage.features')}</th>
                          <th>{t('diagnosisdetailspage.value')}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>HCF</td>
                        
                        <td>{this.state.enabled_features.hfc === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>

                        <tr>
                        <td>HFO</td>
                        <td>{this.state.enabled_features.hfo === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                        <tr>
                          <td>START</td>
                          <td>{this.state.enabled_features.start === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                        <tr>
                        <td>PREMIUM</td>
                        <td>{this.state.enabled_features.premium === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                        <tr>
                        <td>RES 1</td>
                        <td>{this.state.enabled_features.res1 === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                        <tr>
                        <td>RES 2</td>
                        <td>{this.state.enabled_features.res2 === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                        <tr>
                        <td>RES 3</td>
                        <td>{this.state.enabled_features.res3 === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                        <tr>
                        <td>RES 4</td>
                        <td>{this.state.enabled_features.res4 === 1 ? <i className="fa fa-check result_success_icons"></i> : <i className="fa fa-times result_failed_icons"></i>}</td>
                        </tr>
                     
                      </tbody>
                    </table>
                  </div>
              </CardBody>
            </Card>
            </Col>
            <Col lg={4}>
            <Card>
              <CardBody>
              <h4 className="card-title">{t('diagnosisdetailspage.sel_vehicle')}</h4>
                  <div className="table-responsive">
                    <table className="table table-sm m-0 selected_vehicle_table">
                      <thead>
                        <tr>
                        
                          <th>{t('diagnosisdetailspage.property')}</th>
                          <th>{t('diagnosisdetailspage.value')}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{t('diagnosisdetailspage.brand')}</td>
                        <td>{this.state.vehicle.brand}</td>
                        </tr>

                        <tr>
                        <td>{t('diagnosisdetailspage.family')}</td>
                        <td>{this.state.vehicle.family}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.chassis')}</td>
                          <td>{this.state.vehicle.chassis}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.chassis_year')}</td>
                          <td>{this.state.vehicle.chassis_year_from} - {this.state.vehicle.chassis_year_to}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.var_name')}</td>
                          <td>{this.state.vehicle.variant_name}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.var_year')}</td>
                          <td>{this.state.vehicle.variant_year_from} - {this.state.vehicle.variant_year_to}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.fuel')}</td>
                          <td>{this.state.vehicle.fuel === "D" ? "Diesel" : "Petrol"}</td>
                        </tr>
              
                        <tr>
                          <td>{t('diagnosisdetailspage.eng_code')}</td>
                          <td>{this.state.vehicle.engine_code_clear}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.disp')}</td>
                          <td>{this.state.vehicle.displacement}</td>
                        </tr>
                        <tr>
                          <td>Kw</td>
                          <td>{this.state.vehicle.kw}</td>
                        </tr>
                        <tr>
                          <td>Typeidk</td>
                          <td>{this.state.vehicle.typeidk}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.veh_prop_id')}</td>
                          <td>{this.state.vehicle.vehicle_properties_id}</td>
                        </tr>
                        <tr>
                          <td>VIN</td>
                          <td>{this.state.vehicle.vin}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.plate_number')}</td>
                          <td>{this.state.vehicle.plate_number}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.first_reg')}</td>
                          <td>{this.state.vehicle.first_registration !== "0000-00-00" ? moment(this.state.vehicle.first_registration).format('YYYY-MM-DD') : "-"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.last_car_rev')}</td>
                          <td>{this.state.vehicle.last_car_review !== "0000-00-00" ? moment(this.state.vehicle.last_car_review).format('YYYY-MM-DD') : "-"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.next_car_rev')}</td>
                          <td>{this.state.vehicle.next_car_review !== "0000-00-00" ? moment(this.state.vehicle.next_car_review).format('YYYY-MM-DD') : "-"}</td>
                        </tr>
                        
                     
                      </tbody>
                    </table>
                  </div>
              </CardBody>
            </Card>
            </Col>
            <Col lg={4}>
            <Card>
              <CardBody>
              <h4 className="card-title">{t('diagnosisdetailspage.loc_details')}</h4>

                  <div className="table-responsive">
                    <table className="table m-0">
                      <thead>
                        <tr>
                        
                          <th>{t('diagnosisdetailspage.loc_type')}</th>
                          <th>{t('diagnosisdetailspage.value')}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{t('diagnosisdetailspage.ip_add')}</td>
                        <td>{this.state.ip_address !== undefined && this.state.ip_address !== '' ? this.state.ip_address : "N/A"}</td>
                        </tr>

                        <tr>
                        <td>{t('diagnosisdetailspage.cont_code')}</td>
                        <td>{this.state.continent_code !== undefined && this.state.continent_code !== '' ? this.state.continent_code : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.cont_name')}</td>
                          <td>{this.state.continent_name !== undefined && this.state.continent_name !== '' ? this.state.continent_name : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.con_code')}</td>
                          <td>{this.state.country_code !== undefined && this.state.country_code !== '' ? this.state.country_code : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.con_name')}</td>
                          <td>{this.state.country_name !== undefined && this.state.country_name !== '' ? this.state.country_name : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.state')}</td>
                          <td>{this.state.state_prov !== undefined && this.state.state_prov !== '' ? this.state.state_prov : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.city')}</td>
                          <td>{this.state.city !== undefined && this.state.city !== '' ? this.state.city : "N/A"}</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
              <h4 className="card-title">{t('diagnosisdetailspage.customer')}</h4>

                  <div className="table-responsive">
                    <table className="table m-0">
                      
                      <tbody>
                      <tr>
                        <td>{t('diagnosisdetailspage.cus_name')}</td>
                        <td>{this.state.cus_name !== undefined && this.state.cus_name !== '' ? this.state.cus_name : "N/A"}</td>
                        </tr>

                        <tr>
                        <td>{t('diagnosisdetailspage.cus_phone')}</td>
                        <td>{this.state.cus_phone !== undefined && this.state.cus_phone !== '' ? this.state.cus_phone : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.cus_address')}</td>
                          <td>{this.state.cus_address !== undefined && this.state.cus_address !== '' ? this.state.cus_address : "N/A"}</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
              </CardBody>
            </Card>
            </Col>
          </Row>

         <br/>
         
         <Card>
                <CardBody>
                <h5 className="mt-0 mb-3"># AC OPERATIONS</h5>
                  <div id="cd-timeline">
                    <ul className="timeline list-unstyled">
                     <li className="timeline-list">
                        <div className="cd-timeline-content">
                        
                            <div className="operations_div">
                            <br />
                               <h5 className="text-center">AC OPERATIONS</h5>
                               <br />
                            <Row>
                                
                                <Col lg={2}>
                                {this.state.operations.gas_insertion === 1 ?
                                    <Card className="operations_card_success">
                                        <CardBody>
                                        <i className="mdi mdi-gas-cylinder text-center ops_icon"></i>
                                        <p className="text-center"><b>Gas Insertion</b></p>
                                        </CardBody>
                                    </Card> :
                                    <Card className="operations_card_failed">
                                    <CardBody>
                                    <i className="mdi mdi-gas-cylinder text-center ops_icon"></i>
                                    <p className="text-center"><b>Gas Insertion</b></p>
                                    </CardBody>
                                </Card>
                                }
                                </Col>
                                <Col lg={2}>
                                {this.state.operations.gas_recovery === 1 ?
                                    <Card className="operations_card_success">
                                        <CardBody>
                                        <i className="mdi mdi-gas-cylinder text-center ops_icon"></i>
                                        <p className="text-center"><b>Gas Recovery</b></p>
                                        </CardBody>
                                    </Card> :
                                    <Card className="operations_card_failed">
                                    <CardBody>
                                    <i className="mdi mdi-gas-cylinder text-center ops_icon"></i>
                                    <p className="text-center"><b>Gas Recovery</b></p>
                                    </CardBody>
                                </Card>
                                }
                                </Col>
                                <Col lg={2}>
                                {this.state.operations.oil_insertion === 1 ?
                                    <Card className="operations_card_success">
                                        <CardBody>
                                        <i className="mdi mdi-oil text-center ops_icon"></i>
                                        <p className="text-center"><b>Oil Insertion</b></p>
                                        </CardBody>
                                    </Card> :
                                    <Card className="operations_card_failed">
                                    <CardBody>
                                    <i className="mdi mdi-oil text-center ops_icon"></i>
                                    <p className="text-center"><b>Oil Insertion</b></p>
                                    </CardBody>
                                    </Card>
                                }
                                </Col>
                                <Col lg={2}>
                                {this.state.operations.oil_recovery === 1 ?
                                    <Card className="operations_card_success">
                                        <CardBody>
                                        <i className="mdi mdi-oil-level text-center ops_icon"></i>
                                        <p className="text-center"><b>Oil Recovery</b></p>
                                        </CardBody>
                                    </Card> :
                                    <Card className="operations_card_failed">
                                    <CardBody>
                                    <i className="mdi mdi-oil-level text-center ops_icon"></i>
                                    <p className="text-center"><b>Oil Recovery</b></p>
                                    </CardBody>
                                </Card>
                                }
                                </Col>
                                <Col lg={2}>
                                {this.state.operations.vacuum === 1 ?
                                    <Card className="operations_card_success">
                                        <CardBody>
                                        <i className="mdi mdi-fan text-center ops_icon"></i>
                                        <p className="text-center"><b>Vacuum</b></p>
                                        </CardBody>
                                    </Card> :
                                    <Card className="operations_card_failed">
                                    <CardBody>
                                    <i className="mdi mdi-fan text-center ops_icon"></i>
                                    <p className="text-center"><b>Vacuum</b></p>
                                    </CardBody>
                                </Card>
                                }
                                </Col>
                                <Col lg={2}>
                                {this.state.operations.leaks === 1 ?
                                    <Card className="operations_card_success">
                                        <CardBody>
                                        <i className="mdi mdi-pipe-leak text-center ops_icon"></i>
                                        <p className="text-center"><b>Leaks</b></p>
                                        </CardBody>
                                    </Card> :
                                    <Card className="operations_card_failed">
                                    <CardBody>
                                    <i className="mdi mdi-pipe-leak text-center ops_icon"></i>
                                    <p className="text-center"><b>Leaks</b></p>
                                    </CardBody>
                                </Card>
                                }
                                </Col>
                            </Row>
                            </div>
                                
                        {this.state.operations.op_result === "OK" ?
                          <div className="date ops_green">
                            <h4 className="ops_timeline_h4"><b>CMD:</b><p className="mb-0 ops-text">{this.state.operations.cmd}</p></h4>
                            <h4 className="ops_timeline_h4"><b>Operation Result:</b><p className="mb-0 ops-text">{this.state.operations.op_result}</p></h4>
                          </div> :
                          <div className="date ops_red">
                          <h4 className="ops_timeline_h4"><b>CMD:</b><p className="mb-0 ops-text">{this.state.operations.cmd}</p></h4>
                          <h4 className="ops_timeline_h4"><b>Operation Result:</b><p className="mb-0 ops-text">{this.state.operations.op_result}</p></h4>
                        </div>
                         }
                        </div>
                      </li>
                
                    </ul>
                  </div>

                  <Link to={"/ac_operations_report?operation_id="+this.state.operations_details.id}><button className="btn btn-mm waves-effect waves-light " ><i className="ion ion-md-document"></i> Visualizza Report</button></Link>
                </CardBody>
              </Card>
        </div>
        
      </React.Fragment>
    );
  }
}

export default withTranslation()(AcOperationsDetails);

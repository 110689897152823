import React from "react";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  dateFilter,
  multiSelectFilter,
} from "react-bootstrap-table2-filter";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
//import moment from 'moment';
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Modal,
  Row,
  Col,
} from "reactstrap";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

const { ExportCSVButton } = CSVExport;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

const roles = {
  "Administrator and Site Org": "Administrator & Site Org",
  Administrator: "Administrator",
  "AC Operator": "AC Operator",
  Assistance: "Assistance",
  "Diagnosis Developer": "Diagnosis Developer",
  "Diagnosis Tester": "Diagnosis Tester",
  "Diagnosis Manager": "Diagnosis Manager",
  Commercial: "Commercial",
};

const statusSelectOptions = {
  Ricevuta: "RICEVUTA",
  "In analisi": "IN ANALISI",
  "In Lavorazione": "IN LAVORAZIONE",
  Cancellata: "CANCELLATA",
  "In test": "IN TEST",
  "In attesa di rilascio": "IN ATTESA DI RILASCIO",
  Rilasciata: "RILASCIATA",
  Rifiutata: "RIFIUTATA",
  "IN ATTESA": "IN ATTESA",
  "Nuovo sviluppo": "NUOVO SVILUPPO",
};

const typeSelectOptions = {
  Bug: "BUG",
  MIGLIORAMENTO: "MIGLIORAMENTO",
  "NUOVO SVILUPPO": "NUOVO SVILUPPO",
  "Nuova%20funzionalit%C3%A0": "NUOVA FUNZIONALITÀ",
};

class Tickets extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      //value: localStorage.getItem('lang'),
      jira_tickets: [],
      jira_ticket_total: "",
      success_msg: false,
      confirm_msg: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      confirm_both: false,
      img_html: false,
      loading: true,
      logged_user: "",
      logged_username: "",
      jira_summ: "",
      jira_desc_text_html: "",
      date: new Date(),
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.tog_standard = this.tog_standard.bind(this);
    this.addJiraDesc = this.addJiraDesc.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
  }

  tog_standard(loc) {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(user_id);
    this.setState({
      logged_user: user_id,
      logged_username: user_name,
    });

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.users !== 0 || findresponses.diagnosis !== 0) {
        } else {
          window.history.back();
        }
      });

    fetch(config.API + "get_tickets?page=1", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        //console.log(findresponses.total);
        this.setState({
          jira_tickets: findresponses.issues,
          jira_ticket_total: findresponses.total,
          loading: false,
        });
      });
  }

  addJiraDesc(desc) {
    var summery = desc.target.value;
    this.setState({
      jira_desc_text_html: summery,
    });
    this.tog_standard();
    this.createMarkup();
  }
  createMarkup() {
    return { __html: this.state.jira_desc_text_html };
  }

  render() {
    const { t } = this.props;
    const columns = [
      {
        text: t("jira_tickets_page.jira_id"),
        dataField: "key",
        formatter: (rowContent, row) => {
          const issue_key = row.key;
          const issue_type_icon = row.fields.issuetype.iconUrl;
          const issue_type = row.fields.issuetype.name;
          const issue_status = row.fields.status.name;
          if (issue_status === "Done") {
            return (
              <div className="media mb-4" title={issue_type}>
                <img
                  className="d-flex mr-2 "
                  src={issue_type_icon}
                  alt="Generic"
                  height="18"
                  title={issue_type}
                />
                <del>{issue_key}</del>
              </div>
            );
          } else {
            return (
              <div className="media mb-4" title={issue_type}>
                <img
                  className="d-flex mr-2 "
                  src={issue_type_icon}
                  alt="Generic"
                  height="18"
                  title={issue_type}
                />
                {issue_key}
              </div>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.issue_type"),
        dataField: "fields.issuetype.name",
        filter: multiSelectFilter({
          options: typeSelectOptions,
        }),
        formatter: (rowContent, row) => {
          const issue_type = row.fields.issuetype.name;
          return issue_type.toUpperCase();
        },
      },
      {
        text: t("jira_tickets_page.title"),
        dataField: "fields.summary",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          const summary = row.fields.summary;
          const jira_id = row.key;
          return summary;
        },
      },
      {
        text: t("jira_tickets_page.description"),
        dataField: "renderedFields.description",
        formatter: (rowContent, row) => {
          if (
            row.renderedFields.description !== "" &&
            row.renderedFields.description !== null
          ) {
            const desc_text = row.renderedFields.description;
            return (
              <button
                onClick={this.tog_standard && this.addJiraDesc}
                data-toggle="modal"
                value={desc_text}
                data-target="#myModal"
                className="btn btn-outline-nexion loc_btn lg"
              >
                {t("jira_tickets_page.desc_button").toUpperCase()}
              </button>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.desc_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.labels"),
        dataField: "fields.labels[0]",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          if (
            row.fields.labels !== "" &&
            row.fields.labels !== null &&
            row.fields.labels.length > 0
          ) {
            const lables = row.fields.labels[0].toUpperCase();
            return (
              <div className="media mb-4">
                <b>{lables}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.labels_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.serial"),
        dataField: "fields.customfield_10192",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10192 &&
            row.fields.customfield_10192 !== undefined
          ) {
            const serial = row.fields.customfield_10192.toUpperCase();
            return (
              <div className="media mb-4">
                <b>{serial}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.serial_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.brand"),
        dataField: "fields.customfield_10187",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10187 &&
            row.fields.customfield_10187 !== undefined
          ) {
            const brand = row.fields.customfield_10187.toUpperCase();
            return (
              <div className="media mb-4">
                <b>{brand}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.brand_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.model"),
        dataField: "fields.customfield_10188",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10188 &&
            row.fields.customfield_10188 !== undefined
          ) {
            const model = row.fields.customfield_10188.toUpperCase();
            return (
              <div className="media mb-4">
                <b>{model}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.model_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.chassis"),
        dataField: "fields.customfield_10189",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10189 &&
            row.fields.customfield_10189 !== undefined
          ) {
            const chassis = row.fields.customfield_10189.toUpperCase();
            return (
              <div className="media mb-4">
                <b>{chassis}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.chassis_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.eng_code"),
        dataField: "fields.customfield_10190",
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10190 &&
            row.fields.customfield_10190 !== undefined
          ) {
            const eng_code = row.fields.customfield_10190.toUpperCase();
            return (
              <div className="media mb-4">
                <b>{eng_code}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.eng_code_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.rel_ver"),
        dataField: "fields.customfield_10191",
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10191 &&
            row.fields.customfield_10191 !== undefined
          ) {
            const rel_ver = row.fields.customfield_10191.toUpperCase();
            return (
              <div className="media mb-4">
                <b>{rel_ver}</b>
              </div>
            );
          } else {
            return (
              <span className="na_text">
                {t("jira_tickets_page.rel_ver_na").toUpperCase()}
              </span>
            );
          }
        },
      },
      {
        text: t("jira_tickets_page.priority"),
        dataField: "fields.priority.name",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          const priority = row.fields.priority.name;
          const priority_icon = row.fields.priority.iconUrl;
          return (
            <div className="media mb-4">
              <img
                className="d-flex mr-2 "
                src={priority_icon}
                alt="Generic"
                height="18"
              />
              {priority.toUpperCase()}
            </div>
          );
        },
      },
      {
        text: t("jira_tickets_page.status"),
        dataField: "fields.status.name",
        filter: multiSelectFilter({
          options: statusSelectOptions,
        }),
        formatter: (rowContent, row) => {
          const status = row.fields.status.name;
          return (
            <div className="media mb-4">
              <b>{status}</b>
            </div>
          );
        },
      },
      {
        text: t("jira_tickets_page.reason_for_rejection"),
        dataField: "fields.customfield_10193",
        formatter: (rowContent, row) => {
          if (
            row.fields.customfield_10193 &&
            row.fields.customfield_10193 !== undefined
          ) {
            const reason = row.fields.customfield_10193;
            return (
              <div className="media mb-4">
                <b>{reason}</b>
              </div>
            );
          } else {
            return <span className="na_text">N/A</span>;
          }
        },
      },
      // {
      //   text: "ASSIGNEE",
      //   dataField: "fields.assignee.displayName",
      //   filter: textFilter(),
      //   formatter: (rowContent, row) => {
      //     const is_assignee_available = row.fields.assignee;
      //     if (is_assignee_available !== null) {
      //       const assignee = row.fields.assignee.displayName;
      //       const assignee_icon = row.fields.assignee.avatarUrls["48x48"];
      //       return (
      //         <div className="media mb-4">
      //           <img
      //             className="d-flex mr-2 rounded-circle"
      //             src={assignee_icon}
      //             alt="Generic"
      //             height="30"
      //           />
      //           {assignee}
      //         </div>
      //       );
      //     } else {
      //       return <div className="media mb-4">Assignee N/A</div>;
      //     }
      //   },
      // },
      // {
      //   text: "REPORTER",
      //   dataField: "fields.reporter.displayName",
      //   filter: textFilter(),
      //   formatter: (rowContent, row) => {
      //     const reporter = row.fields.reporter.displayName;
      //     const reporter_icon = row.fields.reporter.avatarUrls["48x48"];
      //     return (
      //       <div className="media mb-4">
      //         <img
      //           className="d-flex mr-2 rounded-circle"
      //           src={reporter_icon}
      //           alt="Generic"
      //           height="30"
      //         />
      //         {reporter}
      //       </div>
      //     );
      //   },
      // },
      {
        text: t("jira_tickets_page.created_date"),
        dataField: "fields.created",
        formatter: (rowContent, row) => {
          var up_date = row.fields.created
            .replace(/T/, " ")
            .replace(/\..+/, "");
          return up_date;
        },
      },
    ];

    const headers = [
      { label: t("jira_tickets_page.jira_id"), key: "key" },
      {
        label: t("jira_tickets_page.issue_type"),
        key: "fields.issuetype.name",
      },
      { label: t("jira_tickets_page.title"), key: "fields.summary" },
      {
        label: t("jira_tickets_page.description"),
        key: "renderedFields.description",
      },
      { label: t("jira_tickets_page.labels"), key: "fields.labels[0]" },
      { label: t("jira_tickets_page.serial"), key: "fields.customfield_10192" },
      {
        label: t("jira_tickets_page.brand"),
        key: "fields.fields.customfield_10187",
      },
      { label: t("jira_tickets_page.model"), key: "fields.customfield_10188" },
      {
        label: t("jira_tickets_page.chassis"),
        key: "fields.customfield_10189",
      },
      {
        label: t("jira_tickets_page.eng_code"),
        key: "fields.customfield_10190",
      },
      {
        label: t("jira_tickets_page.rel_ver"),
        key: "fields.customfield_10191",
      },
      { label: t("jira_tickets_page.priority"), key: "fields.priority.name" },
      { label: t("jira_tickets_page.status"), key: "fields.status.name" },
      { label: t("jira_tickets_page.created_date"), key: "fields.created" },
    ];

    const onTableChange = (type, newState) => {
      const filters = newState.filters;
      //console.log(filters);
      if (
        filters["fields.issuetype.name"] &&
        filters["fields.issuetype.name"] !== undefined
      ) {
        var issueTypes = filters["fields.issuetype.name"].filterVal;
      } else {
        var issueTypes = [];
      }

      if (
        filters["fields.status.name"] &&
        filters["fields.status.name"] !== undefined
      ) {
        var statuses = filters["fields.status.name"].filterVal;
      } else {
        var statuses = [];
      }

      if (
        filters["fields.labels[0]"] &&
        filters["fields.labels[0]"] !== undefined
      ) {
        var labels = filters["fields.labels[0]"].filterVal;
      } else {
        var labels = [];
      }

      if (
        filters["fields.customfield_10192"] &&
        filters["fields.customfield_10192"] !== undefined
      ) {
        var serials = filters["fields.customfield_10192"].filterVal;
      } else {
        var serials = [];
      }

      if (
        filters["fields.customfield_10187"] &&
        filters["fields.customfield_10187"] !== undefined
      ) {
        var brands = filters["fields.customfield_10187"].filterVal;
      } else {
        var brands = [];
      }

      if (
        filters["fields.customfield_10188"] &&
        filters["fields.customfield_10188"] !== undefined
      ) {
        var models = filters["fields.customfield_10188"].filterVal;
      } else {
        var models = [];
      }

      if (
        filters["fields.customfield_10189"] &&
        filters["fields.customfield_10189"] !== undefined
      ) {
        var chassises = filters["fields.customfield_10189"].filterVal;
      } else {
        var chassises = [];
      }

      if (
        filters["fields.priority.name"] &&
        filters["fields.priority.name"] !== undefined
      ) {
        var priority = filters["fields.priority.name"].filterVal;
      } else {
        var priority = [];
      }

      fetch(config.API + "get_tickets?page=" + newState.page, {
        headers: {
          Nexion_API_Key: config.API_KEY,
          arr_issueTypes: issueTypes,
          arr_statuses: statuses,
          arr_labels: labels,
          arr_serials: serials,
          arr_brands: brands,
          arr_models: models,
          arr_chassis: chassises,
          arr_priority: priority,
        },
      })
        .then((Responses) => Responses.json())
        .then((findresponses) => {
          this.setState({
            jira_tickets: findresponses.issues,
            jira_ticket_total: findresponses.total,
          });
        });
    };
    const options = {
      sizePerPage: 10,
      pageStartIndex: 1,
      withFirstAndLast: true,
      firstPageText: t("userspage.firstPageText"),
      prePageText: t("userspage.prePageText"),
      nextPageText: t("userspage.nextPageText"),
      lastPageText: t("userspage.lastPageText"),
      nextPageTitle: t("userspage.nextPageTitle"),
      prePageTitle: t("userspage.prePageTitle"),
      firstPageTitle: t("userspage.firstPageTitle"),
      lastPageTitle: t("userspage.lastPageTitle"),
      showTotal: true,
      totalSize: this.state.jira_ticket_total,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
      ],
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.jira_tickets}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "JIRA Tickets Data " + this.state.date + ".csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                <li>
                  <Dropdown
                    isOpen={this.state.btnprimary1}
                    direction="left"
                    toggle={() =>
                      this.setState({ btnprimary1: !this.state.btnprimary1 })
                    }
                  >
                    <DropdownToggle tag="button" className="btn btn-success">
                      {t("userspage.export")}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <ExportCSVButton {...props.csvProps}>
                          {t("userspage.exp_sel")}
                        </ExportCSVButton>
                      </DropdownItem>
                      <DropdownItem>
                        <CSVLink
                          className="react-bs-table-csv-btn btn btn-default"
                          filename={
                            "JIRA Tickets Data " + this.state.date + ".csv"
                          }
                          data={this.state.jira_tickets}
                          headers={headers}
                        >
                          {t("userspage.exp_all")}
                        </CSVLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
              <br />
              <br />
              <hr />
              <BootstrapTable
                striped
                hover
                onTableChange={onTableChange}
                {...props.baseProps}
                selectRow={selectRow}
                filter={filterFactory()}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive jira_table"
                remote={{
                  filter: true,
                  pagination: true,
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );

    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>JIRA Tickets | Magneti Marelli Web Portal</title>
            <meta
              name="description"
              content="Diagnostic Portal For Magneti Marelli Instruments"
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background="#ffd200"
            color={"#002C77"}
          />
          <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
            <div className="modal-body">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <p>
                        <b>{t("jira_tickets_page.description")}:</b>
                        <br></br>
                        <br></br>
                        <div className="test">
                          <span dangerouslySetInnerHTML={this.createMarkup()} />
                        </div>
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.tog_standard}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
          {/* <br/>
         <br/> */}
          <h4 className="text-center">
            {t("jira_tickets_page.jira_page_title")}
          </h4>

          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
          <br />
          <br />
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(Tickets);

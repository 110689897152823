import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from "moment";
import "chartist/dist/scss/chartist.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "./../../helpers/Constants";
import Select from "react-select";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from "reactstrap";
import { Sugar } from "react-preloaders";
import $ from "jquery";

const { ExportCSVButton } = CSVExport;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

class DiagnosisActivations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      visible: false,
      modal_standard: false,
      default_date: new Date(),
      default: false,
      start_date: new Date(),
      end_date: new Date(),
      date: new Date(),
      brands: [],
      family: [],
      chassis: [],
      ecus: [],
      brand_sel: "",
      family_sel: "",
      chassis_sel: "",
      engine_code_sel: "",
      ecus_sel: "",
      engine_code: [],
      basic: false,
      export_btn: false,
      loading: true,
      selected_ecu: "",
      clearable: true,
      all_ecus: [],
      logged_user: "",
      ecu_check: "",
    };

    this.tog_standard = this.tog_standard.bind(this);
    i18next.changeLanguage(localStorage.getItem("lang"));
    // DatePicker
    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.getFilteredDateData = this.getFilteredDateData.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleECUValidSubmit = this.handleECUValidSubmit.bind(this);
    this.handleECUChange = this.handleECUChange.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

  //DatePicker
  handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  handleStart(date) {
    this.setState({ start_date: date });
  }

  handleEnd(date) {
    this.setState({ end_date: date });
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    $(".vertical-center i").on("click", function () {
      var pwidth = 400;

      if ($(this).hasClass("left")) {
        $(".react-bootstrap-table").scrollLeft(
          $(".react-bootstrap-table").scrollLeft() - pwidth
        );
      } else {
        $(".react-bootstrap-table").scrollLeft(
          $(".react-bootstrap-table").scrollLeft() + pwidth
        );
      }
    });

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    });

    //console.log(user_id);
    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org" ||
      authUser.role === "Commercial"
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });

    fetch(config.API + "diagnosis_activation_ecus_test", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          all_ecus: findresponse,
        });
      });

    fetch(config.API + "diagnosis_brands_test", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          brands: findresponse,
          loading: false,
        });
      });
  }

  changeBrand = (event) => {
    this.setState({
      brand_sel: brand,
      family: [],
      chassis: [],
      engine_code: [],
      ecus: [],
      chassis_sel: "",
      family_sel: "",
      engine_code_sel: "",
      ecus_sel: "",
    });
    var brand = event.target.value;
    fetch(config.API + "get_brand_family_test/" + brand, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          brand_sel: brand,
          chassis: [],
          engine_code: [],
          ecus: [],
          chassis_sel: "",
          family_sel: "",
          engine_code_sel: "",
          ecus_sel: "",
        });
        this.setState({
          family: findresponse,
        });
      });
  };

  changeFamily = (event) => {
    this.setState({
      family_sel: family,
      chassis: [],
      engine_code: [],
      ecus: [],
      chassis_sel: "",
      engine_code_sel: "",
      ecus_sel: "",
    });
    var family = event.target.value;
    fetch(
      config.API + "get_family_chassis_test/" + encodeURIComponent(family),
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          family_sel: family,
          engine_code: [],
          ecus: [],
          chassis_sel: "",
          engine_code_sel: "",
          ecus_sel: "",
        });
        this.setState({
          chassis: findresponse,
        });
      });
  };

  changeChassis = (event) => {
    this.setState({
      chassis_sel: chassis,
      engine_code: [],
      ecus: [],
      engine_code_sel: "",
      ecus_sel: "",
    });
    var chassis = event.target.value;
    fetch(config.API + "get_chassis_engine_test/" + chassis, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          chassis_sel: chassis,
          engine_code: [],
          ecus: [],
          engine_code_sel: "",
          ecus_sel: "",
        });
        this.setState({
          engine_code: findresponse,
        });
      });
  };

  changeEngine = (event) => {
    this.setState({
      ecus: [],
      engine_code_sel: engine,
      ecus_sel: "",
    });
    var engine = event.target.value;
    fetch(config.API + "get_engine_ecus_test/" + engine, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          ecus: [],
          engine_code_sel: engine,
          ecus_sel: "",
        });
        this.setState({
          ecus: findresponse,
        });
      });
  };

  changeEcu = (event) => {
    var ecu = event.target.value;
    this.setState({
      ecus_sel: ecu,
    });
  };
  // handleValidSubmit
  handleValidSubmit(event, values) {
    //  console.log(values);
    this.setState({
      products: [],
    });
    fetch(config.API + "get_diag_activations_test", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Nexion_API_Key: config.API_KEY,
      },
      body: JSON.stringify(values),
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        if (findresponse.length <= 0) {
          //alert('No Undecoded DTC Found');
          this.setState({ basic: true });
          this.setState({
            products: [],
          });
        } else {
          this.setState({
            products: findresponse,
          });
        }
      });
  }

  handleECUChange(selectedOption) {
    this.setState({
      ecu_check: selectedOption.label,
    });
  }

  handleECUValidSubmit(event) {
    let ecu = this.state.ecu_check;
    this.setState({
      products: [],
    });
    fetch(config.API + "get_diag_activations_by_ecu_test", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Nexion_API_Key: config.API_KEY,
        ecu: ecu,
      },
      body: JSON.stringify(),
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        if (findresponse.length <= 0) {
          //alert('No Undecoded DTC Found');
          this.setState({ basic: true });
          this.setState({
            products: [],
          });
        } else {
          this.setState({
            products: findresponse,
          });
        }
      });
  }

  getFilteredDateData() {
    const selected_product = localStorage.getItem("sel_product");
    const starting_date = moment(this.state.start_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const ending_date = moment(this.state.end_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (!selected_product) {
      var select_product = "all";
    } else {
      select_product = selected_product;
    }

    fetch(
      config.API +
        "diagnosis_test?starting_date=" +
        starting_date +
        "&ending_date=" +
        ending_date +
        "&sel_product=" +
        select_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          products: findresponse,
        });
      });
    this.tog_standard();
  }

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "Diagnosis Activations Export",
      company: "Nexion",
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  resetpage() {
    window.location.reload(false);
  }

  render() {
    const { t } = this.props;
    //const { value } = this.state;
    let ecu_options = this.state.all_ecus.map(function (ecu) {
      return { value: ecu.name, label: ecu.name };
    });
    const columns = [
      {
        text: "ECU",
        dataField: "ecu",
      },
      {
        text: t("diagnosisactivations.brand"),
        dataField: "brand",
      },
      {
        text: t("diagnosisactivations.family"),
        dataField: "family",
      },
      {
        text: t("diagnosisactivations.chassis"),
        dataField: "chassis",
      },
      {
        text: t("diagnosisactivations.engine_code"),
        dataField: "engine_code_clear",
      },
      {
        text: t("diagnosisactivations.ecu_name"),
        dataField: "name",
      },
      {
        text: "Type",
        dataField: "type",
        formatter: (rowContent, row) => {
          var act_name = row.name;
          if (act_name == "Cancellazione errori") {
            return "CLEAR_DTC";
          } else {
            return row.type;
          }
        },
        //dataField: "type",
      },
      {
        text: "Battery Voltage",
        dataField: "battery",
      },
      {
        text: t("diagnosisactivations.result"),
        dataField: "success",
        formatter: (rowContent, row) => {
          const success = row.success;
          if (success === 0) {
            return (
              <h5>
                <span className="label label-danger">Failed</span>
              </h5>
            );
          } else {
            return (
              <h5>
                <span className="label label-success">Success</span>
              </h5>
            );
          }
        },
      },
      {
        text: "Date & Time",
        dataField: "datetime",
      },
      // {
      //   text: "Discription Index",
      //   dataField: "",
      // },
      // {
      //   text: "Fixed",
      //   dataField: "",
      //   // formatter: (rowContent, row) => {
      //   //     const fix = "not";
      //   //     if (fix === "not") {
      //   //         return (
      //   //           <h5>
      //   //             <span className="label label-danger">NO</span>
      //   //           </h5>
      //   //         );
      //   //       }else{
      //   //         return (
      //   //             <h5>
      //   //               <span className="label label-success">YES</span>
      //   //             </h5>
      //   //           );
      //   //       }
      //   //   },
      // },
      // {
      //   text: "Fix Release",
      //   dataField: "",
      // }
    ];
    const options = {
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("versionpage.firstPageText"),
      prePageText: t("versionpage.prePageText"),
      nextPageText: t("versionpage.nextPageText"),
      lastPageText: t("versionpage.lastPageText"),
      nextPageTitle: t("versionpage.nextPageTitle"),
      prePageTitle: t("versionpage.prePageTitle"),
      firstPageTitle: t("versionpage.firstPageTitle"),
      lastPageTitle: t("versionpage.lastPageTitle"),
      showTotal: true,
      showTotalTitle: "Total Diagnosis",
      totalSize: this.state.products.length,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="seo_id"
          data={this.state.products}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "Diagnosi Activations " + this.state.date + ".csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                {/* <li><button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn filter_btn waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="ti-filter"></i>
                        </button></li> */}
                {this.state.export_btn === true ? (
                  <li>
                    <Dropdown
                      isOpen={this.state.btnprimary1}
                      direction="left"
                      toggle={() =>
                        this.setState({ btnprimary1: !this.state.btnprimary1 })
                      }
                    >
                      <DropdownToggle tag="button" className="btn btn-success">
                        {t("diagnosispage.exp")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <ExportCSVButton {...props.csvProps}>
                            {t("diagnosispage.exp_sel")}
                          </ExportCSVButton>
                        </DropdownItem>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <CSVLink
                            className="react-bs-table-csv-btn btn btn-default"
                            filename={
                              "Diagnosi Activations " + this.state.date + ".csv"
                            }
                            data={this.state.products}
                          >
                            {t("diagnosispage.exp_all")}
                          </CSVLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <br />
              <br />
              <hr />
              <div className="table-wrapper">
                <div className="vertical-center">
                  <i class="ion ion-ios-arrow-dropleft-circle left"></i>
                  <i class="ion ion-ios-arrow-dropright-circle right"></i>
                </div>
                <BootstrapTable
                  striped
                  hover
                  {...props.baseProps}
                  selectRow={selectRow}
                  {...paginationTableProps}
                  pagination={paginationFactory(options)}
                  wrapperClasses="table-responsive"
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    return (
      <React.Fragment>
        {this.state.basic ? (
          <SweetAlert
            title={
              <p className="sweetalert_msg">
                No Activations found for this Vehicle
              </p>
            }
            onConfirm={() => this.setState({ basic: false })}
          ></SweetAlert>
        ) : null}
        <MetaTags>
          <title>Diagnosis Activations | Magneti Marelli Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#ffd200"
          color={"#002C77"}
        />
        <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t("diagnosispage.get_ver_data")}
            </h5>
            <button
              type="button"
              onClick={() => this.setState({ modal_standard: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form action="#">
              <FormGroup className="mb-0 forminput">
                <Row>
                  <Col md={12}>
                    <label>{t("diagnosispage.start_date")}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                      selected={this.state.start_date}
                      selectsStart
                      id="date_pick"
                      className="date_pick form-control"
                      placeholderText="From"
                      name="start_dates"
                      value={this.state.start_date}
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleStart}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>{t("diagnosispage.end_date")}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                      selected={this.state.end_date}
                      selectsEnd
                      id="date_pick"
                      className="form-control"
                      name="end_dates"
                      value={this.state.end_date}
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleEnd}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.resetpage}
              className="btn btn-warning waves-effect"
              data-dismiss="modal"
            >
              {t("diagnosispage.reset_filter")}
            </button>
            <button
              type="button"
              onClick={this.tog_standard}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              {t("diagnosispage.cancel")}
            </button>
            <button
              type="button"
              onClick={this.getFilteredDateData}
              className="btn filter_btn waves-effect waves-light"
            >
              {t("diagnosispage.proceed")}
            </button>
          </div>
        </Modal>
        <div className="container-fluid homepageFirstRow">
          <Row>
            <Col xl={10} className="diag_table_col">
              <Card>
                <CardBody>
                  <p>
                    <b>Filter By Vehicle</b>
                  </p>
                  <AvForm onValidSubmit={this.handleValidSubmit}>
                    <Row>
                      <Col xl="2">
                        <AvField
                          type="select"
                          onChange={this.changeBrand}
                          value={this.state.brand_sel}
                          name="brand"
                          label={t("diagnosisactivations.brand")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.brands.map((item, key) => (
                            <option value={item.brand}>{item.brand}</option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="2">
                        <AvField
                          type="select"
                          onChange={this.changeFamily}
                          value={this.state.family_sel}
                          name="family"
                          label={t("diagnosisactivations.family")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.family.map((item, key) => (
                            <option value={item.family}>{item.family}</option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="4">
                        <AvField
                          type="select"
                          onChange={this.changeChassis}
                          value={this.state.chassis_sel}
                          name="chassis"
                          label={t("diagnosisactivations.chassis")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.chassis.map((item, key) => (
                            <option
                              value={
                                item.chassis +
                                "~" +
                                item.year_from +
                                "~" +
                                item.year_to
                              }
                            >
                              {item.chassis +
                                "(" +
                                item.year_from +
                                "-" +
                                item.year_to +
                                ")"}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="4">
                        <AvField
                          type="select"
                          onChange={this.changeEngine}
                          value={this.state.engine_code_sel}
                          name="engine_code"
                          label={t("diagnosisactivations.engine_code")}
                        >
                          <option value="">{t("adduserspage.select")}</option>
                          {this.state.engine_code.map((item, key) => (
                            <option
                              value={
                                item.variant_name +
                                "~" +
                                item.year_from +
                                "~" +
                                item.year_to +
                                "~" +
                                item.fuel +
                                "~" +
                                item.engine_code_clear +
                                "~" +
                                item.displacement +
                                "~" +
                                item.kw
                              }
                            >
                              {item.variant_name +
                                "(" +
                                item.year_from +
                                "-" +
                                item.year_to +
                                ") " +
                                item.fuel +
                                " " +
                                item.engine_code_clear +
                                " " +
                                item.displacement +
                                " " +
                                item.kw}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="3">
                        <AvField
                          type="select"
                          name="ecu"
                          onChange={this.changeEcu}
                          value={this.state.ecus_sel}
                          label="ECU"
                        >
                          <option value="" selected="selected">
                            {t("adduserspage.select")}
                          </option>
                          {this.state.ecus.map((item, key) => (
                            <option value={item.ecu}>
                              {item.ecu + " --- " + item.code}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                      <Col xl="2">
                        <AvField
                          type="select"
                          name="status"
                          value="all"
                          label={t("diagnosisactivations.result")}
                        >
                          <option value="all" selected="selected">
                            {t("adduserspage.select")}
                          </option>
                          <option value="1">OK</option>
                          <option value="0">NOK</option>
                        </AvField>
                      </Col>
                      <Col xl="2">
                        <div>
                          <Button
                            type="submit"
                            color="mm"
                            className="mr-1 uncoded_dtc_search"
                          >
                            {t("diagnosisactivations.search")}
                          </Button>{" "}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <hr />
                  <p>
                    <b>Filter By ECU</b>
                  </p>

                  <AvForm onValidSubmit={this.handleECUValidSubmit}>
                    <Row>
                      <Col xl="4">
                        {/* <AvField type="select" name="ecu" onChange={this.changeEcu} value={this.state.ecus_sel} label="ECU">
                    <option value="" selected="selected">{t('adduserspage.select')}</option>
                        {this.state.ecus.map((item, key) =>
                            <option value={item.ecu}>{item.ecu+' --- '+item.code}</option>
                        )}
            </AvField> */}
                        <div>
                          <Select
                            name="selected_ecu"
                            onChange={this.handleECUChange}
                            value={this.state.value}
                            placeholder={t("adduserspage.select")}
                            clearable={this.state.clearable}
                            searchable={this.state.searchable}
                            options={ecu_options}
                          />
                        </div>
                      </Col>
                      <Col xl="3">
                        <div>
                          <Button type="submit" color="mm" className="mr-1">
                            {t("diagnosisactivations.search")}
                          </Button>{" "}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <Row>
                            <PaginationProvider
                              pagination={paginationFactory(options)}
                            >
                              {contentTable}
                            </PaginationProvider>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2} className="diag_menu_col">
              <div id="NewmySidenav">
                <Link to="/diagnosis_test" className=" waves-effect">
                  <i className="ion ion-ios-log-in"></i> Diagnosis
                </Link>
                <Link to="/undecoded_dtc_test" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i> Undecoded DTC
                </Link>
                <Link
                  to="/diagnosis_activations_test"
                  className=" waves-effect"
                >
                  <i className="ion  ion-ios-log-in"></i> Activations
                </Link>
                <Link
                  to="/diagnosis_communication_test"
                  className=" waves-effect"
                >
                  <i className="ion  ion-ios-log-in"></i> Diagnosis
                  Communication
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div id="mySidenav" className="sidenav">
          <Row>
            <Col sm="3" className="icon_border">
              <i className="ion ion-ios-list" id="right_sidebar_icon"></i>
            </Col>
            <Col sm="9">
              <Link to="/diagnosis_test" className=" waves-effect">
                Diagnosis
              </Link>
              <Link to="/undecoded_dtc_test" className=" waves-effect">
                Undecoded DTC
              </Link>
              <Link to="/diagnosis_activations_test" className=" waves-effect">
                Activations
              </Link>
              <Link
                to="/diagnosis_communication_test"
                className=" waves-effect"
              >
                Diagnosis Communication
              </Link>
            </Col>
          </Row>
        </div> */}
      </React.Fragment>
    );
  }
}

export default withTranslation()(DiagnosisActivations);

import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/FogetPassword";
import UpdatePwd from "../pages/Authentication/UpdatePassword";
import Dashboard from "../pages/Dashboard";
import Homepage from "../pages/Homepage";
import Versions from "../pages/Versions";
import AcVersions from "../pages/AcVersions/index";
import AcOperations from "../pages/AcOperations/index";
import AcOperationsDetails from "../pages/AcOperations/ac_operations_details";
import AcOperationsReport from "../pages/AcOperations/ac_operations_report";
//import VersionsTest from "../pages/Versions/versions_test";
// import Tables from "../pages/Tables";
import VersionDetails from "../pages/VersionDetails";
import AcVersionDetails from "../pages/AcVersions/ac_version_details";
import VersionContinent from "../pages/Versions/version_continent_wise";
import VersionCountry from "../pages/Versions/version_country_wise";
import VersionsList from "../pages/Versions/versions_list";
import VersionsAll from "../pages/Versions/versions_all";
import Diagnosis from "../pages/Diagnosis";
import DiagnosisTest from "../pages/DiagnosisTest";
import DiagnosisUncodedDtc from "../pages/Diagnosis/uncoded_dtc";
import DiagnosisActivations from "../pages/Diagnosis/diagnosis_activations";
import DiagnosisCommunication from "../pages/Diagnosis/diagnosis_communication";
// import Tables from "../pages/Tables";
import Users from "../pages/Users";
import AddUser from "../pages/Users/add_user";
import EditUser from "../pages/Users/edit_user";
//import EditUsers from "../pages/Users";
import DiagnosisDetails from "../pages/Diagnosis/diagnosis_details";
import DiagnosisReport from "../pages/Diagnosis/diagnosis_report";
import DiagnosisContinent from "../pages/Diagnosis/diagnosis_continent_wise";
import DiagnosisCountry from "../pages/Diagnosis/diagnosis_country_wise";
import DiagnosisList from "../pages/Diagnosis/diagnosis_list";
import DiagnosisAll from "../pages/Diagnosis/diagnosis_all";
import AutoDiagnosis from "../pages/Diagnosis/auto_diagnosis";
import DiagnosisUncodedDtcTest from "../pages/DiagnosisTest/uncoded_dtc";
import DiagnosisAllUncodedDtc from "../pages/Diagnosis/uncoded_dtc_all";
import DiagnosisAllActivations from "../pages/Diagnosis/diagnosis_activations_all";
import DiagnosisAllComm from "../pages/Diagnosis/diagnosis_communication_all";
import DiagnosisActivationsTest from "../pages/DiagnosisTest/diagnosis_activations";
import DiagnosisCommunicationTest from "../pages/DiagnosisTest/diagnosis_communication";
import DiagnosisContinentAll from "../pages/Diagnosis/diagnosis_continent_wise_all";
import DiagnosisCountryAll from "../pages/Diagnosis/diagnosis_country_wise_all";
import DiagnosisDetailsTest from "../pages/DiagnosisTest/diagnosis_details";
import DiagnosisReportTest from "../pages/DiagnosisTest/diagnosis_report";
import DiagnosisContinentTest from "../pages/DiagnosisTest/diagnosis_continent_wise";
import DiagnosisCountryTest from "../pages/DiagnosisTest/diagnosis_country_wise";
import VersionsTest from "../pages/VersionsTest";
import VersionDetailsTest from "../pages/VersionDetailsTest";
// Charts
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import ApexChart from "../pages/Charts/ApexChart";
import SarklineChart from "../pages/Charts/SarklineChart";
import EchartChart from "../pages/Charts/EChart";

//Maps
import MapsVector from "../pages/Maps/MapsVector";
import MapsGoogle from "../pages/Maps/MapsGoogle";
import Tickets from "../pages/Support";
import TicketsDashboard from "../pages/Support/dashboard";
import TicketsDashboardReport from "../pages/Support/jira_dashboard_report";
import DashboardReport from "../pages/Dashboard/dashboard_report";

// Extra Pages
// import PagesLogin2 from "../pages/ExtraPages/PagesLogin2";
// import PagesRegister2 from "../pages/ExtraPages/PagesRegister2";
// import PagesRecoverpw2 from "../pages/ExtraPages/PagesRecoverpw2";
// import PagesLockScreen2 from "../pages/ExtraPages/PagesLockScreen2";

// Extra Pages

import PagesBlank from "../pages/ExtraPages/PagesBlank";
// import Pages404 from "../pages/ExtraPages/Pages404";
// import Pages500 from "../pages/ExtraPages/Pages500";
// import PagesMaintenance from "../pages/ExtraPages/PagesMaintenance";

const authProtectedRoutes = [
  //Charts
  { path: "/charts-chartist", component: ChartistChart },
  { path: "/charts-chartjs", component: ChartjsChart },
  { path: "/apex-chart", component: ApexChart },
  { path: "/charts-sparkline", component: SarklineChart },
  { path: "/e-charts", component: EchartChart },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },

  // Extra Pages

  { path: "/pages-blank", component: PagesBlank },

  { path: "/dashboard", component: Dashboard },
  { path: "/home", component: Homepage },
  { path: "/versions", component: Versions },
  { path: "/ac_versions", component: AcVersions },
  { path: "/ac_operations", component: AcOperations },
  //{ path: "/versions_test", component: VersionsTest },
  { path: "/version_details", component: VersionDetails },
  { path: "/ac_version_details", component: AcVersionDetails },
  { path: "/ac_operations_details", component: AcOperationsDetails },
  { path: "/ac_operations_report", component: AcOperationsReport },
  { path: "/version_continent", component: VersionContinent },
  { path: "/version_country", component: VersionCountry },
  { path: "/diagnosis", component: Diagnosis },
  { path: "/diagnosis_list", component: DiagnosisList },
  { path: "/auto_diagnosis", component: AutoDiagnosis },
  { path: "/diagnosis_all", component: DiagnosisAll },
  { path: "/diagnosis_test", component: DiagnosisTest },
  { path: "/diagnosis_continent_all", component: DiagnosisContinentAll },
  { path: "/diagnosis_country_all", component: DiagnosisCountryAll },
  { path: "/undecoded_dtc_all", component: DiagnosisAllUncodedDtc },
  { path: "/diagnosis_activations_all", component: DiagnosisAllActivations },
  { path: "/diagnosis_communication_all", component: DiagnosisAllComm },
  { path: "/diagnosis_details", component: DiagnosisDetails },
  { path: "/diagnosis_report", component: DiagnosisReport },
  { path: "/diagnosis_continent", component: DiagnosisContinent },
  { path: "/diagnosis_country", component: DiagnosisCountry },
  { path: "/undecoded_dtc", component: DiagnosisUncodedDtc },
  { path: "/diagnosis_activations", component: DiagnosisActivations },
  { path: "/diagnosis_communication", component: DiagnosisCommunication },
  { path: "/diagnosis_details_test", component: DiagnosisDetailsTest },
  { path: "/diagnosis_report_test", component: DiagnosisReportTest },
  { path: "/diagnosis_continent_test", component: DiagnosisContinentTest },
  { path: "/diagnosis_country_test", component: DiagnosisCountryTest },
  { path: "/undecoded_dtc_test", component: DiagnosisUncodedDtcTest },
  { path: "/diagnosis_activations_test", component: DiagnosisActivationsTest },
  { path: "/versions_list", component: VersionsList },
  { path: "/versions_all", component: VersionsAll },
  { path: "/versions_test", component: VersionsTest },
  { path: "/version_details_test", component: VersionDetailsTest },
  {
    path: "/diagnosis_communication_test",
    component: DiagnosisCommunicationTest,
  },
  { path: "/users", component: Users },
  { path: "/add_user", component: AddUser },
  { path: "/edit_user", component: EditUser },
  { path: "/tickets", component: Tickets },
  { path: "/tickets_dashboard", component: TicketsDashboard },
  { path: "/tickets_dashboard_report", component: TicketsDashboardReport },
  { path: "/dashboard_report", component: DashboardReport },
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget_password", component: ForgetPwd },
  { path: "/update_password/:token", component: UpdatePwd },

  // { path: "/pages-register", component: Register },

  // Extra Pages
  // { path: "/pages-login-2", component: PagesLogin2 },
  // { path: "/pages-register-2", component: PagesRegister2 },
  // { path: "/pages-recoverpw-2", component: PagesRecoverpw2 },
  // { path: "/pages-lock-screen-2", component: PagesLockScreen2 },

  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
  // { path: "/pages-Maintenance", component: PagesMaintenance }
];

export { authProtectedRoutes, publicRoutes };

import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import queryString from "query-string";
import logoSm from "../../assets/images/mm_logo.jpg";
import Car from "../../assets/images/car_new.jpg";
import i18next from "i18next";
//import Table from 'react-bootstrap/Table';
//import moment from 'moment';
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import { withTranslation } from "react-i18next";
import config from "./../../helpers/Constants";

class DiagnosisReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosis: [],
      version: [],
      location: [],
      vehicle: [],
      ecus: [],
      battery_info: [],
      activations: [],
      configurations: [],
      dtc: [],
      diag_info: [],
      d_date: "",
      continent_code: "",
      continent_name: "",
      country_code: "",
      country_name: "",
      ip_address: "",
      state_prov: "",
      city: "",
      loading: true,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });
    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const diag_id = values.diagnosis_id;
    if (diag_id === undefined) {
      window.history.back();
    }
    fetch(config.API + "diagnosis_report_test/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        //console.log(findresponse.diagnosis);
        this.setState({
          diagnosis: findresponse.diagnosis,
          activations: findresponse.diag_activations,
          configurations: findresponse.diag_conf,
          ecus: findresponse.ecus,
          dtc: findresponse.ecus_dtc,
          version: findresponse.versions,
          location: findresponse.location,
          vehicle: findresponse.selected_vehicle,
          battery_info: findresponse.battery,
          diag_info: findresponse.diag_info,
          d_date: findresponse.diagnosis.diagnosis_date
            .replace(/T/, " ")
            .replace(/\..+/, ""),
          loading: false,
        });
        if (findresponse.location !== undefined) {
          this.setState({
            continent_code: findresponse.location.continent_code,
            continent_name: findresponse.location.continent_name,
            country_code: findresponse.location.country_code,
            country_name: findresponse.location.country_name,
            city: findresponse.location.city,
            state_prov: findresponse.location.state_prov,
            ip_address: findresponse.location.ip_address,
          });
        }
      });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Diagnosis Report | Magneti Marelli Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#ffd200"
          color={"#002C77"}
        />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box"></div>
            </Col>
          </Row>

          <Row>
            <div className="col-1"></div>
            <div className="col-10 report_class">
              <Card>
                <CardBody className="report_card">
                  <Row>
                    <div className="col-12">
                      <Row>
                        <div className="col-8">
                          <h2 className="font-size-28">Report</h2>
                        </div>
                        <div className="col-4">
                          <div className="invoice-title">
                            <img
                              src={logoSm}
                              className="float-right"
                              alt="logo"
                              height="50"
                            />
                          </div>
                        </div>
                      </Row>
                      <hr />
                      <Row>
                        <div className="col-5">
                          <div className="p-2">
                            <strong>
                              <h5>WORKSHOP DATA</h5>
                            </strong>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td>
                                    {t("diagnosisdetailspage.cont_name")}:
                                  </td>
                                  <td>
                                    {this.state.continent_name !== undefined &&
                                    this.state.continent_name !== ""
                                      ? this.state.continent_name
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.con_name")}:</td>
                                  <td>
                                    {this.state.country_name !== undefined &&
                                    this.state.country_name !== ""
                                      ? this.state.country_name
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.state")}:</td>
                                  <td>
                                    {this.state.state_prov !== undefined &&
                                    this.state.state_prov !== ""
                                      ? this.state.state_prov
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.city")}:</td>
                                  <td>
                                    {this.state.city !== undefined &&
                                    this.state.city !== ""
                                      ? this.state.city
                                      : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-5">
                          <div className="p-2">
                            <strong>
                              <h5>VERSION</h5>
                            </strong>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td>Serial Number:</td>
                                  <td>{this.state.diagnosis.serial_number}</td>
                                </tr>
                                <tr>
                                  <td>SW:</td>
                                  <td>{this.state.version.software}</td>
                                </tr>
                                <tr>
                                  <td>Vehicle DB:</td>
                                  <td>{this.state.version.vehicle_database}</td>
                                </tr>
                                <tr>
                                  <td>Diagnosis:</td>
                                  <td>{this.state.version.autodiagnosis}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-2"></div>
                      </Row>
                      <br />
                      <br />
                      <hr />
                      <Row>
                        <div className="col-8">
                          <div className="p-2">
                            <strong>
                              <h5>VEHICLE DATA</h5>
                            </strong>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td>{t("diagnosisdetailspage.brand")}:</td>
                                  <td>{this.state.vehicle.brand}</td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.family")}:</td>
                                  <td>{this.state.vehicle.family}</td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.eng_code")}:</td>
                                  <td>
                                    {this.state.vehicle.variant_name} [{" "}
                                    {this.state.vehicle.variant_year_from} -{" "}
                                    {this.state.vehicle.variant_year_to} ]{" "}
                                    {this.state.vehicle.fuel === "D"
                                      ? "Diesel"
                                      : "Petrol"}{" "}
                                    {this.state.vehicle.engine_code_clear} -{" "}
                                    {this.state.vehicle.kw} KW{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td>VIN:</td>
                                  <td>{this.state.vehicle.vin}</td>
                                </tr>
                                <tr>
                                  <td>
                                    {t("diagnosisdetailspage.plate_number")}:
                                  </td>
                                  <td>{this.state.vehicle.plate_number}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-4">
                          <img
                            src={Car}
                            className="float-right"
                            alt="car"
                            width="70%"
                          />
                        </div>
                      </Row>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                        <br />
                        <div className="p-2">
                          <strong>
                            <h5>VEHICLE STATUS</h5>
                          </strong>
                          {/* <p><b>Battery:</b>{this.state.battery_info.battery} V</p> */}

                          <p>
                            <b>ECU Scan Date:</b> {this.state.d_date}
                          </p>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>Group</strong>
                                  </td>
                                  {/* <td className="text-center">
                                    <strong>Code</strong>
                                  </td> */}
                                  <td className="text-center">
                                    <strong>ECU</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>Status</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.ecus.map((ecu, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {ecu.e_group}
                                      </td>
                                      {/* <td className="text-center">{ecu.code}</td> */}
                                      <td className="text-center">
                                        {ecu.name}
                                      </td>
                                      <td className="text-right">
                                        {ecu.status.toUpperCase()}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div className="p-2">
                            <strong>
                              <h5>DETECTED ERRORS</h5>
                            </strong>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>ECU</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>DTC</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Hex</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Description</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>ATT/MEM</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dtc.map((dt, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {dt.e_group} - {dt.name}
                                      </td>
                                      <td className="text-center">
                                        {dt.j2012}
                                      </td>
                                      <td className="text-center">{dt.hex}</td>
                                      <td className="text-center">
                                        {dt.description}
                                      </td>
                                      <td className="text-right">
                                        {dt.status}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div className="p-2">
                            <strong>
                              <h5>ICM -INSTRUMENT CLUSTER - IPC</h5>
                            </strong>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>Info</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Value</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.diag_info.map((de, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{de.text}</td>
                                      <td className="text-center">
                                        {de.value}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div className="p-2">
                            <strong>
                              <h5>OPERATIONS CARRIED OUT</h5>
                            </strong>
                            <p>
                              <b>Activations</b>
                            </p>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>Operation</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Result</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Date</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>V Battery</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.activations.map((ac, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{ac.name}</td>
                                      <td className="text-center">
                                        {ac.success === 1
                                          ? "SUCCESS"
                                          : "FAILED"}
                                      </td>
                                      <td className="text-center">
                                        {ac.datetime
                                          .replace(/T/, " ")
                                          .replace(/\..+/, "")}
                                      </td>
                                      <td className="text-center">
                                        {ac.battery}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <p>
                            <b>Configurations</b>
                          </p>
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>Operation</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Result</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Date</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>V Battery</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.configurations.map((cn, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{cn.name}</td>
                                      <td className="text-center">
                                        {cn.success === 1
                                          ? "SUCCESS"
                                          : "FAILED"}
                                      </td>
                                      <td className="text-center">
                                        {cn.datetime
                                          .replace(/T/, " ")
                                          .replace(/\..+/, "")}
                                      </td>
                                      <td className="text-center">
                                        {cn.battery}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <br />
                          <div className="d-print-none">
                            <div className="float-right">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.print();
                                }}
                                className="btn btn-mm waves-effect waves-light"
                              >
                                <i className="fa fa-print"> Stampa Report</i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="col-1"></div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DiagnosisReport);

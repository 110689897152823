import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import {MetaTags} from 'react-meta-tags';
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
//import carImg from "../../assets/images/mcladen.jpg";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// actions
import { loginUser } from "../../store/actions";
import logosmImg from "../../assets/images/mm_tran.png";

// import images
//import logoSm from "../../assets/images/logo-sm.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history);
  }

  componentDidMount() {
    var chk_login = sessionStorage.getItem('authUser');
    if(chk_login){
      this.props.history.push('/home');
    }
  }

  render() {
    return (
      
      <React.Fragment>
        <div className="Login-component">
        <MetaTags>
            <title>Login | Magneti Marelli Web Portal</title>
            <meta name="description" content="Diagnostic Portal For Magneti Marelli Instruments" />
          </MetaTags>
        
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  {this.props.loading ? <Loader /> : null}

                  <Card className="overflow-hidden">
                    <div className="bg-login">
                      <div className="text-mm text-center p-4">
                        <h5 className="text-mm font-size-20">
                          PORTALE DIAGNOSTICA
                        </h5>
                        <p className="text-mm">
                        Accedi per continuare con il portale di diagnostica
                        </p>
                        <Link to="/" className="logo logo-admin">
                          <img src={logosmImg} className="login_img" height="60" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.props.error ? (
                            <Alert color="danger">{this.props.error}</Alert>
                          ) : null}

                          <div className="form-group">
                            <AvField
                              name="email"
                              label="UTENTE"
                              className="form-control"
                              placeholder="Utente"
                              type="text"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="PASSWORD"
                              type="password"
                              required
                              placeholder="Password"
                            />
                          </div>

                          <Row className="form-group">
                            <Col sm={6}>
                              &nbsp;
                              {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                            </Col>
                            <Col sm={6} className="text-right">
                              <button
                                className="btn btn-mm w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </Col>
                          </Row>
                          <Row className="form-group mt-2 mb-0">
                            <div className="col-12 mt-4">
                              <Link to="forget_password">
                                <i className="mdi mdi-lock"></i> Hai dimenticato la password?
                              </Link>
                            </div>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-5 text-center">
                  {/* <p>
                    Don't have an account ?{" "}
                    <Link
                      to="pages-register"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Signup now{" "}
                    </Link>{" "}
                  </p> */}
                  <p className="mb-0 text-white">
                    © {new Date().getFullYear()} Magneti Marelli. <b>- Powered by Nexion Engineering.</b>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(connect(mapStatetoProps, { loginUser })(Login));

import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import {MetaTags} from 'react-meta-tags';
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import config from './../../helpers/Constants';
import Loader from "../../components/Loader";

// import images
import logosmImg from "../../assets/images/mm_tran.png"; 

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    var chk_login = localStorage.getItem('authUser');
    if(chk_login){
      this.props.history.push('/home');
    }
  }

  handleValidSubmit(event, values) {
    
    const options = {
      headers: {
        'Nexion_API_Key': config.API_KEY
      }
    };
    //console.log(user);
    return new Promise((resolve, reject) => {
    
      axios.post(config.API+'forget_password?company=MM', values, options)
        .then(
          user => {
            //console.log(user);
            if (user.data.status === "success") {
              alert("Email Sent Successfully, Please follow the instructions given in the Email!");
              window.location.href = "http://mm.nexiondevportal.com/login"; 
            }else if (user.data.status === "failed") {
              alert("Email ID doesn't Exists!!");
            }
            else{
              alert("There is some technical problem!!");
            }
          },
          error => {
            reject(this._handleError(error));
          })
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="Login-component">
        <MetaTags>
            <title>Forgot Password | Magneti Marelli Web Portal</title>
            <meta name="description" content="Diagnostic Portal For Nexion Engineering" />
          </MetaTags>
        
        <div className="account-pages my-5 pt-5">
        <div className="home-btn d-none d-sm-block forget-component">
        
        <Link to="/" className="text-white">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-login">
                      <div className="text-mm text-center p-4">
                        <h5 className="text-mm font-size-20">
                          Forget Password
                        </h5>
                        <p className="text-mm-50">
                          Enter your email below and we would send a link to
                          reset your password.
                        </p>
                        <Link to="/" className="logo logo-admin">
                        <img src={logosmImg} className="login_img" height="60" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        {this.props.forgetError && this.props.forgetError ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {this.props.forgetError}
                          </Alert>
                        ) : null}
                        {this.props.forgetSuccess ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {this.props.forgetSuccess}
                          </Alert>
                        ) : null}

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-mm w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p className="text-white">
                      Go back to{" "}
                      <Link
                        to="login"
                        className="font-weight-medium text-warning"
                      >
                        Login
                      </Link>{" "}
                    </p>
                    <p className="mb-0 text-white">
                    © {new Date().getFullYear()} Magneti Marelli. <b>- Powered by Nexion Engineering.</b>
                  </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withRouter(
  connect()(ForgetPasswordPage)
);

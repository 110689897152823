import React, { Component } from "react";

// falgs
//import usFlag from "../assets/images/flags/us_flag.jpg";
//import italyFlag from "../assets/images/flags/italy_flag.jpg";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { value: localStorage.getItem("lang") };
    this.handleChange = this.handleChange.bind(this);
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  handleChange(lang) {
    localStorage.setItem("lang", lang.target.value);
    this.setState({ value: lang.target.value });
    i18next.changeLanguage(lang.target.value);
    window.location.reload(false);
    //alert(lang.target.value);
  }

  render() {
    return (
      <React.Fragment>
        {/* <img
              className="mr-2"
              src={usFlag}
              alt="Header Language"
              height="16"
            /> */}
        <select
          className="btn header-item waves-effecte form-control lang_select"
          value={this.state.value}
          onChange={this.handleChange}
        >
          <option className="notify-item align-middle form-control" value="it">
            {" "}
            Itallian{" "}
          </option>
          <option className="notify-item align-middle form-control" value="en">
            {" "}
            English{" "}
          </option>
        </select>
      </React.Fragment>
    );
  }
}

export default withTranslation()(LanguageDropdown);

import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import logoSm from "../../assets/images/mm_logo.jpg";
import i18next from "i18next";
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import { withTranslation } from "react-i18next";
import config from "./../../helpers/Constants";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";

class DashboardReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: localStorage.getItem("lang"),
      loading: true,
      isActive: true,
      curr_date: "",
      diag_ac_user_count: 0,
      diagnosis_count: 0,
      ver_ac_user_count: 0,
      ver_count: 0,
      diag_brand_list: [],
      diag_brand_item: [],
      diag_model_list: [],
      diag_model_item: [],
      diag_ecu_list: [],
      diag_ecu_item: [],
      diag_lang_list: [],
      diag_lang_item: [],
      diag_location_count: [],
      ver_location_count: [],
      diag_tester_list: [],
      diag_tester_item: [],
      ver_tester_list: [],
      ver_tester_item: [],
      ver_lang_list: [],
      ver_lang_item: [],
      ver_ac_password: 0,
      ver_exp_password: 0,
      ver_tester_undefined: 0,
      ver_tester_android: 0,
      ver_tester_windows: 0,
      ver_tester_ap_undefined: 0,
      ver_tester_ap_android: 0,
      ver_tester_ap_windows: 0,
      ver_tester_total: 0,
      ver_tester_ap_total: 0,
      daysvalue: localStorage.getItem("data_visible_days"),
      diag_au_daysvalue: "",
      diag_daysvalue: "",
      diag_brands_daysvalue: "",
      diag_models_daysvalue: "",
      diag_lang_daysvalue: "",
      diag_ecu_daysvalue: "",
      diag_map_daysvalue: "",
      diag_tester_daysvalue: "",
      ver_au_daysvalue: "",
      ver_daysvalue: "",
      ver_map_daysvalue: "",
      ver_tester_daysvalue: "",
      ver_tester_d_daysvalue: "",
      ver_tester_ap_daysvalue: "",
      ver_ap_daysvalue: "",
      ver_lang_daysvaule: "",
      loading: true,
      sum_ac_user_count: 0,
      sum_au_daysvalue: "",
      sum_ac_password: 0,
      sum_exp_password: 0,
      sum_ap_daysvalue: "",
      sum_all_ac_password: 0,
      sum_all_exp_password: 0,
      sum_all_ap_daysvalue: "",
      sum_os_windows: 0,
      sum_os_android: 0,
      sum_os_undefined: 0,
      sum_os_daysvalue: "",
      sum_all_ap_total: 0,
      sum_os_total: 0,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var data_visible_days = localStorage.getItem("data_visible_days");
    this.setState({
      diag_au_daysvalue: data_visible_days,
      diag_daysvalue: data_visible_days,
      diag_brands_daysvalue: data_visible_days,
      diag_map_daysvalue: data_visible_days,
      diag_tester_daysvalue: data_visible_days,
      ver_au_daysvalue: data_visible_days,
      ver_daysvalue: data_visible_days,
      ver_map_daysvalue: data_visible_days,
      ver_tester_daysvalue: data_visible_days,
      ver_tester_d_daysvalue: data_visible_days,
      ver_tester_ap_daysvalue: data_visible_days,
      ver_ap_daysvalue: data_visible_days,
      ver_lang_daysvaule: data_visible_days,
      diag_models_daysvalue: data_visible_days,
      diag_lang_daysvalue: data_visible_days,
      diag_ecu_daysvalue: data_visible_days,
      curr_date: moment().format("DD-MM-YYYY hh:mm:ss"),
    });

    if (!data_visible_days) {
      var day = "14";
    } else {
      day = data_visible_days;
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        this.setState({
          loading: false,
        });
        if (findresponses.users === 0) {
          window.history.back();
        }
      });

    //diag active users
    fetch(config.API + "get_dashboard_active_users/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          diag_ac_user_count: findresponse.diag_count,
        });
      });

    //diagnosis count
    fetch(config.API + "get_dashboard_diagnosis/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          diag_count: findresponse.diag_count,
        });
      });

    //diagnosis brands
    fetch(
      config.API + "get_dashboard_diagnosis_brands/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_brand_list: findresponse.brands.b_list,
          diag_brand_item: findresponse.brands.b_items,
        });
      });

    //diagnosis Models
    fetch(
      config.API + "get_dashboard_diagnosis_models/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_model_list: findresponse.models.b_list,
          diag_model_item: findresponse.models.b_items,
        });
      });

    //diagnosis Ecus
    fetch(
      config.API +
        "get_dashboard_diagnosis_ecu/" +
        day +
        "?company=MM&lang=" +
        localStorage.getItem("lang"),
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_ecu_list: findresponse.models.b_list,
          diag_ecu_item: findresponse.models.b_items,
        });
      });

    //diagnosis Lang
    fetch(config.API + "get_dashboard_diagnosis_lang/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_lang_list: findresponse.languages.b_list,
          diag_lang_item: findresponse.languages.b_items,
        });
      });

    //Version Lang
    fetch(config.API + "get_dashboard_version_lang/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_lang_list: findresponse.languages.b_list,
          ver_lang_item: findresponse.languages.b_items,
        });
      });

    //Version Active Password
    fetch(config.API + "get_dashboard_version_ap/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_ac_password: findresponse.ac_password,
          ver_exp_password: findresponse.exp_password,
        });
      });

    //diagnosis location count
    fetch(
      config.API + "get_dashboard_diagnosis_location/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_location_count: findresponse.italy_count,
        });
      });

    //version location count
    fetch(
      config.API + "get_dashboard_version_location/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_location_count: findresponse.italy_count,
        });
      });

    //version count
    fetch(config.API + "get_dashboard_versions/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_count: findresponse.ver_count,
        });
      });

    //version ac user count
    fetch(
      config.API + "get_dashboard_version_active_users/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_ac_user_count: findresponse.ver_count,
        });
      });

    //Diagnosis Tester
    fetch(
      config.API + "get_dashboard_diagnosis_tester/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          diag_tester_list: findresponse.testers.t_list,
          diag_tester_item: findresponse.testers.t_items,
        });
      });

    //Version Tester
    fetch(config.API + "get_dashboard_version_tester/" + day + "?company=MM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_tester_list: findresponse.testers.t_list,
          ver_tester_item: findresponse.testers.t_items,
        });
      });

    //Version Tester distribution
    fetch(
      config.API + "get_dashboard_version_tester_os/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse[2].total);

        if (findresponse[0].total) {
          this.setState({
            ver_tester_undefined: findresponse[0].total,
          });
        } else {
          this.setState({
            ver_tester_undefined: 0,
          });
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_android: findresponse[1].total,
          });
        } else {
          this.setState({
            ver_tester_android: 0,
          });
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_windows: findresponse[2].total,
          });
        } else {
          this.setState({
            ver_tester_windows: 0,
          });
        }
        this.setState({
          ver_tester_total:
            findresponse[0].total +
            findresponse[1].total +
            findresponse[2].total,
        });
      });

    //Version Tester distribution Active Password
    fetch(
      config.API + "get_dashboard_version_tester_os_ap/" + day + "?company=MM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse[1].total);

        if (findresponse[0].total) {
          this.setState({
            ver_tester_ap_undefined: findresponse[0].total,
          });
        } else {
          this.setState({
            ver_tester_ap_undefined: 0,
          });
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_ap_android: findresponse[1].total,
          });
        } else {
          this.setState({
            ver_tester_ap_android: 0,
          });
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_ap_windows: findresponse[2].total,
          });
        } else {
          this.setState({
            ver_tester_ap_windows: 0,
          });
        }

        this.setState({
          ver_tester_ap_total:
            findresponse[0].total +
            findresponse[1].total +
            findresponse[2].total,
        });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Dashboard Report | Magneti Marelli Web Portal</title>{" "}
          <meta
            name="description"
            content="Diagnostic Portal For Magneti Marelli Instruments"
          />{" "}
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#ffd200"
          color={"#002C77"}
        />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box"></div>
            </Col>
          </Row>

          <Row>
            <div className="col-1"></div>
            <div className="col-10 report_class">
              <Card>
                <CardBody className="report_card">
                  <Row>
                    <div className="col-12">
                      <Row>
                        <div className="col-8">
                          <h2 className="font-size-20">
                            <i class="mdi mdi-jira jira_report"></i>DIAGNOSIS
                            DASHBOARD REPORT
                          </h2>
                          <p>
                            <b>Report Date:</b> {this.state.curr_date}
                          </p>
                        </div>
                        <div className="col-4">
                          <div className="invoice-title">
                            <img
                              src={logoSm}
                              className="float-right"
                              alt="logo"
                              height="50"
                            />
                          </div>
                        </div>
                      </Row>
                      <hr />
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                        <div>
                          <strong>
                            <h5> DIAGNOSIS: </h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 rep-table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>
                                      {t("homepage.active_users")}
                                    </strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>{t("homepage.diagnosis")}</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    {this.state.diag_ac_user_count}
                                  </td>
                                  <td className="text-center">
                                    {this.state.diag_count}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br />
                        <Row>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.brands")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  {this.state.diag_brand_list.map(
                                    (brand, index) => {
                                      return (
                                        <tr>
                                          <th className="text-left">
                                            <strong>{brand}</strong>
                                          </th>
                                          <td className="text-left">
                                            {this.state.diag_brand_item[index]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.models")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  {this.state.diag_model_list.map(
                                    (model, index) => {
                                      return (
                                        <tr>
                                          <th className="text-left">
                                            <strong>{model}</strong>
                                          </th>
                                          <td className="text-left">
                                            {this.state.diag_model_item[index]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> PLANTS </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  {this.state.diag_ecu_list.map(
                                    (ecu, index) => {
                                      return (
                                        <tr>
                                          <th className="text-left">
                                            <strong>{ecu}</strong>
                                          </th>
                                          <td className="text-left">
                                            {this.state.diag_ecu_item[index]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.languages")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  {this.state.diag_lang_list.map(
                                    (lang, index) => {
                                      return (
                                        <tr>
                                          <th className="text-left">
                                            <strong>{lang}</strong>
                                          </th>
                                          <td className="text-left">
                                            {this.state.diag_lang_item[index]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <br />
                        <br />
                        <Row>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.countries")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <tr>
                                    <th className="text-left">
                                      <strong>Italy</strong>
                                    </th>
                                    <td className="text-left">
                                      {this.state.diag_location_count}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>India</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>Brazil</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>United States</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>Russia</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>France</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>Spain</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>China</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.tester_type")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <thead>
                                    <tr>
                                      <td className="text-center">
                                        <strong>Div.0</strong>
                                      </td>
                                      {/* <td className="text-center">
                                        <strong>iONE Cruiser</strong>
                                      </td> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-center">
                                        {this.state.diag_tester_item}
                                      </td>
                                      {/* <td className="text-center">0</td> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <br />
                        <br />
                        <Row>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6>
                                  {" "}
                                  {t(
                                    "homepage.tester_type_distribution_overall"
                                  )}{" "}
                                </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <thead>
                                    <tr>
                                      <td className="text-center">
                                        <strong>Windows</strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>Android</strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>Undefined</strong>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-center">
                                        {this.state.ver_tester_windows}
                                      </td>
                                      <td className="text-center">
                                        {this.state.ver_tester_android}
                                      </td>
                                      <td className="text-center">
                                        {this.state.ver_tester_undefined}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6>
                                  {" "}
                                  {t(
                                    "homepage.tester_type_distribution_with_active_password"
                                  )}{" "}
                                </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <thead>
                                    <tr>
                                      <td className="text-center">
                                        <strong>Windows</strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>Android</strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>Undefined</strong>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-center">
                                        {this.state.ver_tester_ap_windows}
                                      </td>
                                      <td className="text-center">
                                        {this.state.ver_tester_ap_android}
                                      </td>
                                      <td className="text-center">
                                        {this.state.ver_tester_ap_undefined}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      {/* Versions */}
                      <br />
                      <hr />
                      <div>
                        <div>
                          <strong>
                            <h5> VERSION: </h5>
                          </strong>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 rep-table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>
                                      {t("homepage.ver_active_users")}
                                    </strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>
                                      {t("homepage.dash_version")}
                                    </strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    {this.state.ver_ac_user_count}
                                  </td>
                                  <td className="text-center">
                                    {this.state.ver_count}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br />
                        <Row>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.active_password")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <thead>
                                    <tr>
                                      <td className="text-center">
                                        <strong>
                                          {t("homepage.active_password")}
                                        </strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>
                                          {t("homepage.expired_password")}
                                        </strong>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-center">
                                        {this.state.ver_ac_password}
                                      </td>
                                      <td className="text-center">
                                        {this.state.ver_exp_password}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.languages")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  {this.state.ver_lang_list.map(
                                    (lang, index) => {
                                      return (
                                        <tr>
                                          <th className="text-left">
                                            <strong>{lang}</strong>
                                          </th>
                                          <td className="text-left">
                                            {this.state.ver_lang_item[index]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <br />
                        <br />
                        <Row>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.countries")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <tr>
                                    <th className="text-left">
                                      <strong>Italy</strong>
                                    </th>
                                    <td className="text-left">
                                      {this.state.ver_location_count}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>India</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>Brazil</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>United States</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>Russia</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>France</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>Spain</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                  <tr>
                                    <th className="text-left">
                                      <strong>China</strong>
                                    </th>
                                    <td className="text-left">0</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} sm={6}>
                            <div className="">
                              <strong>
                                <h6> {t("homepage.tester_type_ver")} </h6>
                              </strong>
                              <div className="table-responsive">
                                <table className="table table-sm m-0 rep-table-bordered">
                                  <thead>
                                    <tr>
                                      <td className="text-center">
                                        <strong>Div.0</strong>
                                      </td>
                                      {/* <td className="text-center">
                                        <strong>iONE Cruiser</strong>
                                      </td> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-center">
                                        {this.state.ver_tester_item}
                                      </td>
                                      {/* <td className="text-center">0</td> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      {/* Final Table with print Button */}
                      <div>
                        <div className="">
                          <br />
                          <div className="d-print-none">
                            <div className="float-right">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.print();
                                }}
                                className="btn btn-mm waves-effect waves-light"
                              >
                                <i className="fa fa-print"> Stampa Report</i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="col-1"></div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DashboardReport);

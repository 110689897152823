import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
//import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, FormGroup, Button} from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {MetaTags} from 'react-meta-tags';
//import Loader from "../../components/Loader";
// action
import { registerUser } from "../../store/actions";
import {Sugar} from 'react-preloaders';
import queryString from 'query-string';
// Redux
import { connect } from "react-redux";
import axios from "axios";
import config from './../../helpers/Constants';

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      states: [],
      cities: [],
      user:[],
      user_info:[],
      lang:[],
      roles:[],
      country:'',
      states_selected:'',
      city:'',
      role:'',
      loading:true
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    i18next.changeLanguage(localStorage.getItem('lang'));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.users === 0){
        window.history.back();
      }
    })
   
    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const uid = values.id;
    if(uid === undefined){
        window.history.back();
    }

    fetch(config.API+'edit_user/'+uid, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
     this.setState({
      user_info:findresponse.users,
      lang:findresponse.langs,
      roles:findresponse.roles
    })

  if(findresponse.address.country !== undefined){
    this.setState({
    country:findresponse.address.country.id
    })
  }
  if(findresponse.address.states !== undefined){
    this.setState({
    states_selected:findresponse.address.states.id,
    })
  }
  if(findresponse.users.city !== undefined){
    this.setState({
    city:findresponse.users.city,
    })
  }
  
  if(findresponse.users.role === "custom"){
    document.getElementsByClassName("role_chk_div")[0].style.display = "block";
  }else{
    document.getElementsByClassName("role_chk_div")[0].style.display = "none";
  }
  var cid= findresponse.users.country;
    fetch(config.API+'get_states/'+cid, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse)
      this.setState({
          states:findresponse
      })
    })

    var sid= findresponse.users.state;
    fetch(config.API+'get_cities/'+sid, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
        //console.log(findresponse)
        this.setState({
            cities:findresponse,
      })
    })
    })
  
    fetch(config.API+'get_coutries', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse)
      this.setState({
        countries:findresponse,
        loading:false
      })
      
    })
    }

    changeCountry = (event) => {
        var cid= event.target.value;
        fetch(config.API+'get_states/'+cid, {
          headers: {  
            'Nexion_API_Key': config.API_KEY
          }
        }).then((Response)=>Response.json()).then((findresponse)=>{
            //console.log(findresponse)
            this.setState({
                states:findresponse,
            })
            
            })
      };

      changeRole = (event) => {
        var role= event.target.value;
    
        if(role === "custom"){
          document.getElementsByClassName("role_chk_div")[0].style.display = "block";
        }else{
          document.getElementsByClassName("role_chk_div")[0].style.display = "none";
        }
      };

      // changeState = (event) => {
      //   var sid= event.target.value;
      //   fetch(config.API+'get_cities/'+sid, {
      //     headers: {  
      //       'Nexion_API_Key': config.API_KEY
      //     }
      //   }).then((Response)=>Response.json()).then((findresponse)=>{
      //       //console.log(findresponse)
      //       this.setState({
      //           cities:findresponse,
      //       })
            
      //       })
      // };

       // handleValidSubmit
  handleValidSubmit(event, values) {
    //console.log(values);
    //this.props.editUser(values);
    const options = {
      headers: {
        'Nexion_API_Key': config.API_KEY
      }
    };
    return new Promise((resolve, reject) => {
    
      axios.post(config.API+'update_user', values, options)
        .then(
          user => {
            //console.log(user);
            if (user.data.status === "success") {
              alert("User Updated Successfully");
              window.location.reload(false);       
            }else if (user.data.status === "failed") {
              alert("There is some problem while updating user");
            }
            else{
              alert("There is some problem while updating user");
            }
          },
          error => {
            reject(this._handleError(error));
          })
    });

  }


  render() {
    const { t } = this.props;
    //const { value } = this.state;
    //alert(this.state.roles);
    const model = {
      roles_chk:[
        "diagnosis",
        "homestats",
        "versions"
      ]
    } 
      
    return (
      <React.Fragment>
         <MetaTags>
      <title>Edit User | Magneti Marelli Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ffd200" color={'#002C77'} />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{t('adduserspage.add_user')}</h4>
                {/* {this.props.loading ? <Loader /> : null} */}
              </div>
            </Col>
          </Row>
          <AvForm
          
          onValidSubmit={this.handleValidSubmit}
          model={model}
        >
          
          <Card>
            <CardBody>
          <Row>
            <Col lg={6}>

                    <AvField
                      name="username"
                      disabled="disabled"
                      label= {t('adduserspage.username')}
                      placeholder={t('adduserspage.username_place')}
                      type="text"
                      value={this.state.user_info.username}
                      errorMessage={t('adduserspage.username_err')}
                      validate={{ required: { value: true } }}
                    />
                    <label>{t('adduserspage.password')}</label>
                    <AvField
                      name="password"
                      type="text"
                      value={this.state.user_info.new_password}
                      placeholder={t('adduserspage.password_place')}
                      errorMessage={t('adduserspage.password_err')}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="email"
                      disabled="disabled"
                      label={t('adduserspage.email')}
                      placeholder={t('adduserspage.email_place')}
                      type="email"
                      value={this.state.user_info.email}
                      errorMessage={t('adduserspage.email_err')}
                      validate={{
                        required: { value: true },
                        email: { value: true }
                      }}
                    />
                    
                    <AvField type="select" value={this.state.user_info.disable} name="dis" label={t('adduserspage.disable')}>
                    <option value="0">No</option>
                    <option value="1" selected>Yes</option>
                    </AvField>
                    
              <AvField type="select" onChange={this.changeRole} value={this.state.user_info.role} name="role" label={t('adduserspage.role')} required errorMessage={t('adduserspage.role_err')}> 
              {this.state.user_info.role === "Administrator" ? <option value="Administrator">Administrator</option> : ""}
              <option value="AC Operator">AC Operator</option>
              <option value="Assistance">Assistance</option>
              <option value="Diagnosis Manager">Diagnosis Manager</option>
              <option value="Diagnosis Tester">Diagnosis Tester</option>
              <option value="Commercial">Commercial</option>
              </AvField>
              <AvField type="hidden" name="user_id" value={this.state.user_info.id} />

              <div className="role_chk_div">
                <AvCheckboxGroup inline name="roles_chk">
                  <AvCheckbox label="Home Page Statistics" value="homestats" />
                  <AvCheckbox label="Users" value="users" />
                  <AvCheckbox label="Diagnosis" value="diagnosis" /> 
                  <AvCheckbox label="Versions" value="versions" /> 
                  <AvCheckbox label="Diagnosis Test Records" value="diag_test" />
                  <AvCheckbox label="Version Test Records" value="ver_test" />
                </AvCheckboxGroup>
              </div>

              <AvField disabled="disabled" type="select" name="soc" value={this.state.user_info.soc} label={t('adduserspage.soc')} required errorMessage={t('adduserspage.soc_err')}>
              <option value="">{t('adduserspage.select')}</option>
              <option value="MM">Magneti Marelli</option>
              
              </AvField>

              {/* <AvField type="select" name="fam" value={this.state.user_info.fam} label={t('adduserspage.fam')} required errorMessage={t('adduserspage.fam_err')}>
              <option value="">{t('adduserspage.select')}</option>
              <option value="Europe">Europe</option>
              <option value="Argentine">Argentine</option>
              <option value="Brazil">Brazil</option>
              </AvField> */}

              <AvField type="select" multiple="multiple" value={this.state.lang} name="lang" label={t('adduserspage.ling')} required errorMessage={t('adduserspage.ling_err')}>
              {/* <option value="">{t('adduserspage.select')}</option> */}
								<option value="alb">Albanian</option>
								<option value="ara">Arabic</option>
								<option value="bra">Brazilian</option>
								<option value="bul">Bulgarian</option>
								<option value="cec">Czech</option>
								<option value="cin" selected="selected">Chinese</option>
								<option value="cro">Croatian</option>
								<option value="fin">Finnish</option>
								<option value="fra">French</option>
								<option value="gre">Greek</option>
								<option value="ing">English</option>
								<option value="ita">Italian</option>
								<option value="jap">Japanese</option>
								<option value="nor">Norwegian</option>
								<option value="ola">Dutch</option>
								<option value="pol">Polish</option>
								<option value="por">Portuguese</option>
								<option value="rum">Romanian</option>
								<option value="rus">Russian</option>
								<option value="ser">Serbian</option>
								<option value="spa">Spanish</option>
								<option value="ted">German</option>
								<option value="tur">Turkish</option>
								<option value="ung">Hungarian</option>
              </AvField>
                
            </Col>

            <Col lg={6}>
                <AvField
                      name="firstname"
                      label={t('adduserspage.firstname')}
                      placeholder={t('adduserspage.fname_place')}
                      type="text"
                      value={this.state.user_info.firstname}
                      errorMessage={t('adduserspage.fname_err')}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="lastname"
                      label={t('adduserspage.lastname')}
                      placeholder={t('adduserspage.lname_place')}
                      type="text"
                      value={this.state.user_info.lastname}
                      errorMessage={t('adduserspage.lname_err')}
                      validate={{ required: { value: true } }}
                    />
                
                    <AvField
                      name="phone"
                      label={t('adduserspage.phone')}
                      placeholder={t('adduserspage.phone_place')}
                      type="number"
                      value={this.state.user_info.phone}
                      errorMessage={t('adduserspage.phone_err')}
                    />
                <AvField type="select" onChange={this.changeCountry} value={this.state.country} name="country" label={t('adduserspage.country')}>
                {/* <option value="">{t('adduserspage.select')}</option> */}
                {this.state.countries.map((item, key) =>
                
                   <option value={item.id}>{item.name}</option>
                )}
              
              </AvField>
              <AvField type="select" value={this.state.states_selected} name="state" label={t('adduserspage.province')}>
              <option value="">{t('adduserspage.select')}</option>
                {this.state.states.map((item, key) =>
                    <option value={item.id}>{item.name}</option>
                )}
              
              </AvField>
              <AvField
                      name="city"
                      label={t('adduserspage.city')}
                      placeholder={t('adduserspage.city')}
                      value={this.state.city}
                      type="text"
                    />
              {/* <AvField type="select" name="city" value={this.state.city} label={t('adduserspage.city')}>
              <option value="">{t('adduserspage.select')}</option>
                {this.state.cities.map((item, key) =>
                    <option value={item.id}>{item.name}</option>
                )}
              
              </AvField> */}
              <AvField type="textarea" value={this.state.user_info.address} name="address" id="address" placeholder={t('adduserspage.address')}>
              </AvField>
                    <AvField
                      name="postalcode"
                      label={t('adduserspage.postalcode')}
                      placeholder={t('adduserspage.postalcode')}
                      type="number"
                      value={this.state.user_info.postal_code}
                      errorMessage={t('adduserspage.postalcode')}
                      validate={{
                        // required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: t('adduserspage.postalcode')
                        }
                      }}
                    />
                    <AvField
                      name="fiscalcode"
                      label={t('adduserspage.fiscalcode')}
                      placeholder={t('adduserspage.fiscalcode')}
                      type="text"
                      value={this.state.user_info.fiscal_code}
                      errorMessage={t('adduserspage.fiscalcode')}
                      validate={{
                        // required: { value: true },
                        pattern: {
                          value: "^[0-9a-zA-Z]+$",
                          errorMessage: t('adduserspage.fiscalcode')
                        }
                      }}
                    />
                     <AvField
                      name="piva"
                      label={t('adduserspage.piva')}
                      placeholder={t('adduserspage.piva')}
                      type="text"
                      value={this.state.user_info.piva}
                      errorMessage={t('adduserspage.piva')}
                    //   validate={{ required: { value: true } }}
                    />
                    
                  

            </Col>
            </Row>
            <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="mm" className="mr-1">
                        {t('adduserspage.save')}
                        </Button>{" "}
                      </div>
                    </FormGroup>
            </CardBody>
            </Card>
            </AvForm>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
  };
  
  export default withTranslation() (connect(mapStatetoProps, { registerUser })(EditUser));
//export default AddUser;

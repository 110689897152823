import React from 'react';
import { VectorMap } from '@south-paw/react-vector-maps';
import styled from "@emotion/styled";
import "../../css/jquery-jvectormap.css";
import '../../assets/scss/custom.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from 'moment';
import {MetaTags} from 'react-meta-tags';
import $ from 'jquery';
import queryString from 'query-string';
import config from './../../helpers/Constants';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Sugar} from 'react-preloaders';

const strumselectOptions = {
    'Div.0': 'DIV.0'
};
const cksselectOptions = {
    '1': 'OK',
    '0': 'NOK'
};
const socselectOptions = {
    'MM': 'Magneti Marelli'
};
const lingselectOptions = {
    'it-IT': 'Italian',
    'alb': 'Albanian',
    'ara': 'Arabic',
    'bra': 'Brazilian',
    'bul': 'Bulgarian',
    'cec': 'Czech',
    'cin': 'Chinese',
    'cro': 'Croatian',
    'fin': 'Finnish',
    'fra': 'French',
    'gre': 'Greek',
    'en-US': 'English',
    'jap': 'Japanese',
    'nor': 'Norwegian',
    'ola': 'Dutch',
    'pol': 'Polish',
    'por': 'Portuguese',
    'rum': 'Romanian',
    'rus': 'Russian',
    'ser': 'Serbian',
    'spa': 'Spanish',
    'ted': 'German',
    'tur': 'Turkish',
    'ung': 'Hungarian'
};
const { ExportCSVButton } = CSVExport;

const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };
  const Map = styled.div`
  margin: 1rem auto;

  svg {
    stroke: #fff;

    // All layers are just path elements
    path {
      fill: #e7e7e7;
      cursor: pointer;
      outline: none;

      // When a layer is hovered
      &:hover {
        fill: rgba(49, 91, 163);
      }

      // When a layer is focused.
      &:focus {
        fill: rgba(0, 44, 119);
      }

      // When a layer is 'checked' (via checkedLayers prop).
      &[aria-checked='true'] {
        fill: rgba(252, 203, 0);
      }

      // When a layer is 'selected' (via currentLayers prop).
      &[aria-current='true'] {
        fill: rgba(252, 203, 0);
      }

      // You can also highlight a specific layer via it's id
      &[id="nz-can"] {
        fill: rgba(56,43,168,0.6);
      }
    }
  }
`;
  
class VersionsContinent extends React.Component {
    constructor(props) {
        super(props);
        const continent = queryString.parse(this.props.location.search);
//const version_id = values.id;
const cont = continent.code;
        //this.state = {};
        this.state = {
          //value: localStorage.getItem('lang'),
          products: [],
          visible: false,
          continent_name: cont,
          modal_standard: false,
          default_date: new Date(),
          default: false,
          start_date: new Date(),
          end_date: new Date(),
          date: new Date(),
          tablewidth: 9,
          export_btn:false,
          loading:true
        };
        
        this.tog_standard = this.tog_standard.bind(this);
        i18next.changeLanguage(localStorage.getItem('lang'));
           // DatePicker
        this.handleDefault = this.handleDefault.bind(this);
        this.handleAutoClose = this.handleAutoClose.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleEnd = this.handleEnd.bind(this);
        this.getFilteredDateData = this.getFilteredDateData.bind(this);
      }
      

      //DatePicker
      handleDefault(date) {
        this.setState({ default_date: date });
      }
      handleAutoClose(date) {
        this.setState({ auto_close: date });
      }
    
      handleStart(date) {
        this.setState({ start_date: date });
      }
      
      handleEnd(date) {
        this.setState({ end_date: date });
      }
      
      tog_standard() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();
      }
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }
      goBack() {
        window.history.back();
      }
      
      tog_map() {
        document.getElementsByClassName("map_div")[0].style.display = "none";
        document.getElementsByClassName("hide_map")[1].style.display = "none";
        document.getElementsByClassName("show_map")[0].style.display = "block";
        localStorage.setItem('map_display', "no");
        //document.body.classList.add("no_map");
        this.setState({ tablewidth: 12 });
        //this.setState({ mapdisplay: false });
    
      }
    
      show_map() {
        document.getElementsByClassName("map_div")[0].style.display = "block";
        document.getElementsByClassName("hide_map")[1].style.display = "block";
        document.getElementsByClassName("show_map")[0].style.display = " none";
        //document.body.classList.add("no_map");
        localStorage.setItem('map_display', "yes");
        this.setState({ tablewidth: 9 });
        //this.setState({ mapdisplay: false });
    
      }
      
      show() {
        this.setState({ visible: true });
      }
      hide() {
        this.setState({ visible: false });
      }
    
      handleClick = (e, countryCode) => {
        alert('You Have Selected '+ countryCode);
    
     };

  componentDidMount() {

    $('.vertical-center i').on('click',function(){
      var pwidth = 400;

      if($(this).hasClass('left')){
        $('.react-bootstrap-table').scrollLeft( $('.react-bootstrap-table').scrollLeft()-pwidth );
      } else {
          $('.react-bootstrap-table').scrollLeft($('.react-bootstrap-table').scrollLeft()+pwidth );
      };


    });
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);
    if(authUser.role === "Administrator" || authUser.role === "Diagnosis Manager" || authUser.role === "Administrator and Site Org" || authUser.role === "Commercial"){
      this.setState({
        export_btn: true,
        map_btn: true
      })
      
    }

    const map_show = localStorage.getItem('map_display');
if(map_show === "yes"){
document.getElementsByClassName("show_map")[0].style.display = "none";
document.getElementsByClassName("map_div")[0].style.display = "block";
document.getElementsByClassName("hide_map")[0].style.display = "block";
this.setState({
  tablewidth: 9,
})

}else{

document.getElementsByClassName("show_map")[0].style.display = "block";
document.getElementsByClassName("map_div")[0].style.display = "none";
document.getElementsByClassName("hide_map")[0].style.display = "none";
this.setState({
  tablewidth: 12,
})
}

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.versions === 0){
        window.history.back();
      }
    })

    const continent = queryString.parse(this.props.location.search);
    const cont = continent.code;
    const data_visible_days = localStorage.getItem('data_visible_days');
    const selected_product = localStorage.getItem('sel_product');
    
    //var days = "14";
    if(!data_visible_days){
      var days = "14";
    }else{
       days = data_visible_days;
    }
    if(!selected_product){
      var select_product = "all";
    }else{
      select_product = selected_product;
    }

    fetch(config.API+'version_country_data/'+cont+'?days='+days+'&sel_product='+select_product+'&company=MM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse)
      this.setState({
        products:findresponse,
        loading:false
      })
      
    })
  }
  
   getFilteredDateData() {
    const continent = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const cont = continent.code;
    const selected_product = localStorage.getItem('sel_product');
    const starting_date = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
    const ending_date =moment(this.state.end_date).format('YYYY-MM-DD HH:mm:ss');
    if(!selected_product){
      var select_product = "all";
    }else{
      select_product = selected_product;
    }
    
    fetch(config.API+'version_country_data/'+cont+'?starting_date='+starting_date+'&ending_date='+ending_date+'&sel_product='+select_product+'&company=MM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse)
      this.setState({
        products:findresponse,
      })
    })
    this.tog_standard();
  }
  resetpage() {
    window.location.reload(false);
  }

  render() {

    const mapclick = ({ target }) => {
        const name = target.attributes.name.value;
        alert("you have clicked on test "+name);
        // window.open(`https://www.google.com/search?q=${name}%20nz`)
      }
   
    const { t } = this.props;
    const columns = [
      {
          text: t('versionpage.version_id'),
          dataField: "id",
          filter: textFilter()
        },
        {
          text: t('versionpage.ser_no'),
          dataField: "serial_number",
          filter: textFilter(),
          formatter: (rowContent, row) => {
              const sn_no = row.serial_number;
              const id = row.id;
              return (    
                  <Link to={"/version_details?id="+id+"&serial_no="+sn_no} target="_blank"><button className='btn btn-outline-primary btn lg'>{row.serial_number}</button></Link>
              )
            }
        },
        {
          text: t('versionpage.strum'),
          dataField: "device_name",
          filter: selectFilter({
              options: strumselectOptions
          })
        },
        {
          text: t('versionpage.ver_soft'),
          dataField: "software",
          filter: textFilter()
        },
        {
          text: t('versionpage.ver_auto'),
          dataField: "autodiagnosis",
          filter: textFilter()
        },
        {
          text: t('versionpage.ver_db_auto'),
          dataField: "vehicle_database",
          filter: textFilter()
        },
        {
          
          text: t('versionpage.exp'),
          dataField: "expiring_date",
          formatter: (rowContent, row) => {
            const exp_date = moment(row.expiring_date).format('YYYY-MM-DD');
            //const id = row.id
            return (    
                exp_date
            )
          },
          filter: dateFilter(),
        },
        {
          text: t('versionpage.password'),
          dataField: "password",
          filter: textFilter()
        },
        {
          text: t('versionpage.ling'),
          dataField: "language",
          filter: selectFilter({
              options: lingselectOptions
          }),
        },
        {
          text: t('versionpage.soc'),
          dataField: "company",
          filter: selectFilter({
              options: socselectOptions
          }),
        },
        {
          text: t('versionpage.cks'),
          isDummyField: true,
          dataField: "update_result",
          filter: selectFilter({
              options: cksselectOptions
          }),
          formatter: (cellContent, row) => {
              if (row.update_result) {
              return (
                <h5>
                  <span className="label label-success">OK</span>
                </h5>
              );
            }else{
              return (
                  <h5>
                    <span className="label label-danger">NOK</span>
                  </h5>
                );
            }
          }
          },
        {
          text: t('versionpage.int_date'),
          dataField: "update_date",
          filter: dateFilter(),
          formatter: (rowContent, row) => {
            var up_date = row.update_date.replace(/T/, ' ').replace(/\..+/, '');
            //const id = row.id;
            return (    
              up_date
            )
          },
        }
  ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('versionpage.firstPageText'),
      prePageText: t('versionpage.prePageText'),
      nextPageText: t('versionpage.nextPageText'),
      lastPageText: t('versionpage.lastPageText'),
      nextPageTitle: t('versionpage.nextPageTitle'),
      prePageTitle: t('versionpage.prePageTitle'),
      firstPageTitle: t('versionpage.firstPageTitle'),
      lastPageTitle: t('versionpage.lastPageTitle'),
      showTotal: true,
      totalSize: this.state.products.length,
      sizePerPageList: [
        {
          text: '10', value: 10
        }, 
        {
          text: '50', value: 50
        },{
          text: '100', value: 100
        }
      ]
    };
    const continent = queryString.parse(this.props.location.search);
//const version_id = values.id;
const cont = continent.code;
var country = require('../../components/maps/'+cont+'.json');
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="container-fluid">
          <ToolkitProvider
            keyField="id"
            data={ this.state.products }
            columns={ columns }
            exportCSV={ {  exportAll: false , onlyExportSelection: true, fileName: 'Version Data '+this.state.date+'.csv',} }
            >
            {
                props => (
                <div>
                    <ul className="export_btns">
                    <li><button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn filter_btn waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="ti-filter"></i>
                        </button></li>
                        {this.state.export_btn === true ? 
                      <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({ btnprimary1: !this.state.btnprimary1 })
                        }
                      >
                      <DropdownToggle tag="button" className="btn btn-success">
                      {t('versionpage.export')}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></DropdownItem>
                        <DropdownItem><CSVLink className="react-bs-table-csv-btn btn btn-default" filename={"Version Data "+this.state.date+".csv"} data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                      </li> : ""
                      }
                      <li><button
                          type="button"
                          onClick={() => this.tog_map()}
                          className="btn hide_map btn-danger waves-effect waves-light"
                        >
                          {t('diagnosispage.hide_map')}
                        </button></li>
                        <li><button
                          type="button"
                          onClick={() => this.show_map()}
                          className="btn show_map btn-danger waves-effect waves-light"
                        >
                          {t('diagnosispage.show_map')}
                        </button></li>
                        {/* <li><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></li>
                        <li><CSVLink className="react-bs-table-csv-btn btn btn-default" data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></li> */}
                    </ul>
                        <br/>
                        <br/>
                        <hr/>
                        <div className="table-wrapper">
                    <div className="vertical-center"><i class="ion ion-ios-arrow-dropleft-circle left"></i><i class="ion ion-ios-arrow-dropright-circle right"></i></div>
                    <BootstrapTable
                    striped
                    hover
                    { ...props.baseProps }
                    selectRow={ selectRow }
                    filter={ filterFactory() }
                    { ...paginationTableProps }
                    pagination={ paginationFactory(options) }
                    wrapperClasses="table-responsive"
                    />
                    </div>
                </div>
                )
            }
            </ToolkitProvider>
      </div>
    );

    return (
      
      <Card className="homepageFirstRow">
          <CardBody>
          <MetaTags>
      <title>Versions | Magneti Marelli Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ffd200" color={'#002C77'} />
          {/* <br/>
         <br/> */}
          {/* <h4 className="text-center">{t('versionpage.version_rec')}</h4> */}

                      <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                          {t('versionpage.get_ver_data')}
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          
                          <Form action="#">
                              <FormGroup className="mb-0 forminput">
                             
                              <Row>
                                <Col md={12}>
                                  <label>{t('versionpage.start_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.start_date}
                                    selectsStart
                                    id="date_pick"
                                    className="date_pick form-control"
                                    placeholderText="From"
                                    name="start_dates"
                                    value={this.state.start_date} 
                                    // onChange={this.handleEmailChange}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleStart}
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col md={12}>
                                <label>{t('versionpage.end_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.end_date}
                                    selectsEnd
                                    id="date_pick"
                                    className="form-control"
                                    name="end_dates"
                                    value={this.state.end_date}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleEnd}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        </div>
                        <div className="modal-footer">
                        <button
                            type="button"
                            onClick={this.resetpage}
                            className="btn btn-warning waves-effect"
                            data-dismiss="modal"
                          >
                            {t('versionpage.reset_filter')}
                          </button>
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            {t('versionpage.cancel')}
                          </button>
                          <button
                            type="button"
                            onClick={this.getFilteredDateData}
                            className="btn filter_btn waves-effect waves-light"
                          >
                            {t('versionpage.proceed')}
                          </button>
                        </div>
                      </Modal>
                    
        <div className="container-fluid homepageFirstRow">
                         
          <Row>
            <Col xl={3} className="map_div">
            <Card>
            
                <CardBody>
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-mm"></i> {t('homepage.countries')}</h4>
                  {/* <p>{t('homepage.last_14_days')}</p> */}
                  <button onClick={() => this.goBack()} className="btn hide_map btn-dark waves-effect waves-light float_right"><i className="far fa-arrow-alt-circle-left"></i> Continent Map</button>
                  <br/><br/><br/>
                <Row>
                <Col xl={12}>
                  <div style={{ width: this.props.width, height: 420 }}>
                  <Map>
                  <VectorMap {...country} layerProps={{ mapclick }} />
                  </Map>
                    </div>
                  </Col>
                </Row>
                
                </CardBody>
            </Card>
            </Col>

            <Col xl={this.state.tablewidth}>
            <Card>
                <CardBody>
                <Row>
                <PaginationProvider
                    pagination={
                        paginationFactory(options)
                    }
                    >
                    { contentTable }
                  </PaginationProvider>
                </Row>
                </CardBody>
            </Card>
            </Col>
          </Row>
        </div>
        <br/>
        <br/>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(VersionsContinent);